// tslint:disable
/**
 * Сервис Начислений
 * API Сервиса Начислений (charge-invoice)
 *
 * The version of the OpenAPI document: 1.5.7
 * Contact: aa.kolinko@mts.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url'
import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface AbstractResponse
 */
export interface AbstractResponse {
  /**
   * Код ошибки возникшей при обработке запроса
   * @type {number}
   * @memberof AbstractResponse
   */
  errorCode: AbstractResponseErrorCodeEnum
  /**
   * Описание ошибки возникшей при обработке запроса
   * @type {string}
   * @memberof AbstractResponse
   */
  errorMessage?: string
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof AbstractResponse
   */
  requestId: string
}

/**
 * @export
 * @enum {string}
 */
export enum AbstractResponseErrorCodeEnum {
  NUMBER_0 = 0,
  NUMBER_1000 = 1000,
  NUMBER_48026 = 48026,
  NUMBER_48027 = 48027,
  NUMBER_48028 = 48028,
  NUMBER_48029 = 48029,
  NUMBER_48030 = 48030,
}

/**
 * Информация о сумме платежа
 * @export
 * @interface Amount
 */
export interface Amount {
  /**
   * Сумма платежа (в копейках)
   * @type {number}
   * @memberof Amount
   */
  base: number
  /**
   * Валюта платежа
   * @type {number}
   * @memberof Amount
   */
  currency: AmountCurrencyEnum
}

/**
 * @export
 * @enum {string}
 */
export enum AmountCurrencyEnum {
  NUMBER_643 = 643,
}

/**
 * Информация о процессе диалога с клиентом в рамках направленного ему предложения, для которого получен отклик
 * @export
 * @interface Aoc
 */
export interface Aoc {
  /**
   * Номер начисления
   * @type {string}
   * @memberof Aoc
   */
  id: string
  /**
   *
   * @type {Reply}
   * @memberof Aoc
   */
  reply: Reply
}
/**
 * Детализация оказанных услуг, по которым был рассчитан текущий баланс ЛС
 * @export
 * @interface BalanceDetail
 */
export interface BalanceDetail {
  /**
   * Разрядность счетчика – количество цифр в показаниях прибора учета
   * @type {number}
   * @memberof BalanceDetail
   */
  capacity?: number
  /**
   * Код услуги
   * @type {string}
   * @memberof BalanceDetail
   */
  code: string
  /**
   * Название услуги для отображения клиенту
   * @type {string}
   * @memberof BalanceDetail
   */
  name?: string
  /**
   * Номер прибора учета
   * @type {string}
   * @memberof BalanceDetail
   */
  number?: string
  /**
   * Идентификатор тарифа
   * @type {string}
   * @memberof BalanceDetail
   */
  tarifId?: string
  /**
   * Единица измерения (для количества - value)
   * @type {string}
   * @memberof BalanceDetail
   */
  unit?: string
  /**
   * Количество
   * @type {string}
   * @memberof BalanceDetail
   */
  value?: string
}
/**
 * Параметр начисления
 * @export
 * @interface ChargeDetail
 */
export interface ChargeDetail {
  /**
   * Имя параметра отображаемое пользователю
   * @type {string}
   * @memberof ChargeDetail
   */
  description: string
  /**
   * Имя параметра
   * @type {string}
   * @memberof ChargeDetail
   */
  name: string
  /**
   * Тип параметра
   * @type {string}
   * @memberof ChargeDetail
   */
  type: ChargeDetailTypeEnum
  /**
   * Значение параметра соответствующее типу
   * @type {object}
   * @memberof ChargeDetail
   */
  value: object
}

/**
 * @export
 * @enum {string}
 */
export enum ChargeDetailTypeEnum {
  STRING = 'STRING',
  LIST = 'LIST',
}

/**
 * Запрос дополнительной информации по начислению
 * @export
 * @interface ChargeDetailsRequest
 */
export interface ChargeDetailsRequest {
  /**
   * Тип начисления
   * @type {string}
   * @memberof ChargeDetailsRequest
   */
  chargeType: ChargeDetailsRequestChargeTypeEnum
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof ChargeDetailsRequest
   */
  requestId: string
  /**
   * Идентификатор начисления
   * @type {string}
   * @memberof ChargeDetailsRequest
   */
  uin: string
}

/**
 * @export
 * @enum {string}
 */
export enum ChargeDetailsRequestChargeTypeEnum {
  FINES = 'FINES',
  TRANSPORT = 'TRANSPORT',
}

/**
 * Ответ на запрос дополнительной информации по начислению
 * @export
 * @interface ChargeDetailsResponse
 */
export interface ChargeDetailsResponse {
  /**
   * Список параметров начисления (отсутствует в случае ошибки)
   * @type {Array<ChargeDetail>}
   * @memberof ChargeDetailsResponse
   */
  chargeInfoDetails?: Array<ChargeDetail>
  /**
   * Код ошибки возникшей при обработке запроса
   * @type {number}
   * @memberof ChargeDetailsResponse
   */
  errorCode: ChargeDetailsResponseErrorCodeEnum
  /**
   * Описание ошибки возникшей при обработке запроса
   * @type {string}
   * @memberof ChargeDetailsResponse
   */
  errorMessage?: string
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof ChargeDetailsResponse
   */
  requestId: string
}

/**
 * @export
 * @enum {string}
 */
export enum ChargeDetailsResponseErrorCodeEnum {
  NUMBER_0 = 0,
  NUMBER_1000 = 1000,
  NUMBER_48026 = 48026,
  NUMBER_48027 = 48027,
  NUMBER_48028 = 48028,
  NUMBER_48029 = 48029,
  NUMBER_48030 = 48030,
}

/**
 * Информация о начислении, как она была возвращена в ответе на вызов getSearchChargesResult за исключением признака isDetailsAvailable
 * @export
 * @interface ChargeInfo
 */
export interface ChargeInfo {
  /**
   * Идентификатор операции в ПЦ ЭК, которой оплатили начисление, обязательно, если markType = SYSTEM, должен отсутствовать, если markType = CLIENT
   * @type {string}
   * @memberof ChargeInfo
   */
  mdOrder?: string
  /**
   *
   * @type {PaymentDetailsRequest}
   * @memberof ChargeInfo
   */
  paymentDetails: PaymentDetailsRequest
  /**
   * Идентификатор услуги (типа платежа) ПЦ ЭК, которой соответствует начисление, обязательно для CLIENT markType
   * @type {string}
   * @memberof ChargeInfo
   */
  serviceId?: string
}
/**
 * Информация о клиенте, полученная от поставщика услуги
 * @export
 * @interface ClientInfo
 */
export interface ClientInfo {
  /**
   * Адрес клиента
   * @type {string}
   * @memberof ClientInfo
   */
  address?: string
}
/**
 * Запрос на создание подписки
 * @export
 * @interface CreateSubscriptionRequest
 */
export interface CreateSubscriptionRequest {
  /**
   *
   * @type {ParamsAutopayment}
   * @memberof CreateSubscriptionRequest
   */
  autoPaymentParams?: ParamsAutopayment
  /**
   * Максимальная сумма автоматической оплаты счетов
   * @type {number}
   * @memberof CreateSubscriptionRequest
   */
  maxSumAutoPay?: number
  /**
   * ФИО плательщика. Используется в случае оплаты счетов, найденных по подписке в автоматическом режиме: при оплате с помощью ответной СМС и если к подписке подключен АП
   * @type {string}
   * @memberof CreateSubscriptionRequest
   */
  payerName?: string
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof CreateSubscriptionRequest
   */
  requestId: string
  /**
   * Идентификатор типа платежа ПЦ ЭК
   * @type {string}
   * @memberof CreateSubscriptionRequest
   */
  serviceId: string
  /**
   * Название подписки (максимум 250 символов)
   * @type {string}
   * @memberof CreateSubscriptionRequest
   */
  subscriptionName: string
  /**
   *
   * @type {SearchParameters}
   * @memberof CreateSubscriptionRequest
   */
  subscriptionParams: SearchParameters
  /**
   * Тип подписки
   * @type {string}
   * @memberof CreateSubscriptionRequest
   */
  type: CreateSubscriptionRequestTypeEnum
  /**
   * Идентификатор кошелька в ПЦ ЭК
   * @type {string}
   * @memberof CreateSubscriptionRequest
   */
  userId: string
}

/**
 * @export
 * @enum {string}
 */
export enum CreateSubscriptionRequestTypeEnum {
  AUTOPAYMENT = 'AUTO_PAYMENT',
  WITHCONFIRM = 'WITH_CONFIRM',
}

/**
 * Ответ на запрос на создание подписки
 * @export
 * @interface CreateSubscriptionResponse
 */
export interface CreateSubscriptionResponse {
  /**
   * Уникальный идентификатор подключаемого автоплатежа, если errorCode==0
   * @type {string}
   * @memberof CreateSubscriptionResponse
   */
  apId?: string
  /**
   * Код ошибки возникшей при обработке запроса
   * @type {number}
   * @memberof CreateSubscriptionResponse
   */
  errorCode: CreateSubscriptionResponseErrorCodeEnum
  /**
   * Описание ошибки возникшей при обработке запроса
   * @type {string}
   * @memberof CreateSubscriptionResponse
   */
  errorMessage?: string
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof CreateSubscriptionResponse
   */
  requestId: string
  /**
   * Идентификатор созданной подписки, обязательно присутствует если errorCode = 0
   * @type {string}
   * @memberof CreateSubscriptionResponse
   */
  subscriptionId?: string
}

/**
 * @export
 * @enum {string}
 */
export enum CreateSubscriptionResponseErrorCodeEnum {
  NUMBER_0 = 0,
  NUMBER_1000 = 1000,
  NUMBER_48026 = 48026,
  NUMBER_48027 = 48027,
  NUMBER_48028 = 48028,
  NUMBER_48029 = 48029,
  NUMBER_48030 = 48030,
}

/**
 * Запрос удаления метки у начислений в списке оплаченных
 * @export
 * @interface DeletePaidChargeRequest
 */
export interface DeletePaidChargeRequest {
  /**
   * Список начислений для сохранения
   * @type {Array<string>}
   * @memberof DeletePaidChargeRequest
   */
  charges: Array<string>
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof DeletePaidChargeRequest
   */
  requestId: string
}
/**
 * Ответ на запрос удаления метки у начислений в списке оплаченных
 * @export
 * @interface DeletePaidChargeResponse
 */
export interface DeletePaidChargeResponse {
  /**
   * Код ошибки возникшей при обработке запроса
   * @type {number}
   * @memberof DeletePaidChargeResponse
   */
  errorCode: DeletePaidChargeResponseErrorCodeEnum
  /**
   * Описание ошибки возникшей при обработке запроса
   * @type {string}
   * @memberof DeletePaidChargeResponse
   */
  errorMessage?: string
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof DeletePaidChargeResponse
   */
  requestId: string
}

/**
 * @export
 * @enum {string}
 */
export enum DeletePaidChargeResponseErrorCodeEnum {
  NUMBER_0 = 0,
  NUMBER_1000 = 1000,
  NUMBER_48026 = 48026,
  NUMBER_48027 = 48027,
  NUMBER_48028 = 48028,
  NUMBER_48029 = 48029,
  NUMBER_48030 = 48030,
}

/**
 * Запрос на удаление подписки
 * @export
 * @interface DeleteSubscriptionRequest
 */
export interface DeleteSubscriptionRequest {
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof DeleteSubscriptionRequest
   */
  requestId: string
  /**
   * Идентификатор подписки
   * @type {string}
   * @memberof DeleteSubscriptionRequest
   */
  subscriptionId: string
  /**
   * Идентификатор кошелька в ПЦ ЭК
   * @type {string}
   * @memberof DeleteSubscriptionRequest
   */
  userId: string
}
/**
 * Ответ на запрос на удаление подписки
 * @export
 * @interface DeleteSubscriptionResponse
 */
export interface DeleteSubscriptionResponse {
  /**
   * Код ошибки возникшей при обработке запроса
   * @type {number}
   * @memberof DeleteSubscriptionResponse
   */
  errorCode: DeleteSubscriptionResponseErrorCodeEnum
  /**
   * Описание ошибки возникшей при обработке запроса
   * @type {string}
   * @memberof DeleteSubscriptionResponse
   */
  errorMessage?: string
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof DeleteSubscriptionResponse
   */
  requestId: string
}

/**
 * @export
 * @enum {string}
 */
export enum DeleteSubscriptionResponseErrorCodeEnum {
  NUMBER_0 = 0,
  NUMBER_1000 = 1000,
  NUMBER_48026 = 48026,
  NUMBER_48027 = 48027,
  NUMBER_48028 = 48028,
  NUMBER_48029 = 48029,
  NUMBER_48030 = 48030,
}

/**
 * Информация о начислении
 * @export
 * @interface EWCharge
 */
export interface EWCharge {
  /**
   * Признак возможности запросить детали начисления
   * @type {boolean}
   * @memberof EWCharge
   */
  isDetailsAvailable: boolean
  /**
   *
   * @type {PaymentDetails}
   * @memberof EWCharge
   */
  paymentDetails: PaymentDetails
  /**
   * Список UIN начислений, которые являются основаниями для данного начисления
   * @type {Array<string>}
   * @memberof EWCharge
   */
  reasonCharges?: Array<string>
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof EWCharge
   */
  searchParams?: { [key: string]: string }
  /**
   * Идентификатор услуги (тип платежа) ПЦ ЭК
   * @type {string}
   * @memberof EWCharge
   */
  serviceId: string
  /**
   *
   * @type {StateDetails}
   * @memberof EWCharge
   */
  stateDetails?: StateDetails
}
/**
 * Начисление
 * @export
 * @interface FNSData
 */
export interface FNSData {
  /**
   *
   * @type {Amount}
   * @memberof FNSData
   */
  amount: Amount
  /**
   *
   * @type {PaymentDetail}
   * @memberof FNSData
   */
  paymentDetails: PaymentDetail
  /**
   * Критерии для поиска начислений
   * @type {{ [key: string]: string; }}
   * @memberof FNSData
   */
  searchParams: { [key: string]: string }
  /**
   * Индентификатор платежа из каталога ПЦЭК
   * @type {string}
   * @memberof FNSData
   */
  serviceId: string
  /**
   *
   * @type {StateDetails}
   * @memberof FNSData
   */
  stateDetails: StateDetails
}
/**
 * Запрос фотографий по начислениям.
 * @export
 * @interface FileManagerRequest
 */
export interface FileManagerRequest {
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof FileManagerRequest
   */
  requestId: string
  /**
   *
   * @type {SearchParameters}
   * @memberof FileManagerRequest
   */
  searchParams: SearchParameters
}
/**
 * Ответ на запрос фотографий по начислениям.
 * @export
 * @interface FileManagerResponse
 */
export interface FileManagerResponse {
  /**
   * Код ошибки возникшей при обработке запроса
   * @type {number}
   * @memberof FileManagerResponse
   */
  errorCode: FileManagerResponseErrorCodeEnum
  /**
   * Описание ошибки возникшей при обработке запроса
   * @type {string}
   * @memberof FileManagerResponse
   */
  errorMessage?: string
  /**
   * Информация по штрафу
   * @type {Array<Fine>}
   * @memberof FileManagerResponse
   */
  fines: Array<Fine>
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof FileManagerResponse
   */
  requestId: string
}

/**
 * @export
 * @enum {string}
 */
export enum FileManagerResponseErrorCodeEnum {
  NUMBER_0 = 0,
  NUMBER_1000 = 1000,
  NUMBER_48026 = 48026,
  NUMBER_48027 = 48027,
  NUMBER_48028 = 48028,
  NUMBER_48029 = 48029,
  NUMBER_48030 = 48030,
}

/**
 * Запрос фотографий по УИН
 * @export
 * @interface FileManagerUinRequest
 */
export interface FileManagerUinRequest {
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof FileManagerUinRequest
   */
  requestId: string
  /**
   * Уникальный идентификатор начисления по ГИБДД
   * @type {string}
   * @memberof FileManagerUinRequest
   */
  uin: string
}
/**
 * Ответ на запрос фотографий по начислениям.
 * @export
 * @interface FileManagerUinResponse
 */
export interface FileManagerUinResponse {
  /**
   * Код ошибки возникшей при обработке запроса
   * @type {number}
   * @memberof FileManagerUinResponse
   */
  errorCode: FileManagerUinResponseErrorCodeEnum
  /**
   * Описание ошибки возникшей при обработке запроса
   * @type {string}
   * @memberof FileManagerUinResponse
   */
  errorMessage?: string
  /**
   *
   * @type {Fine}
   * @memberof FileManagerUinResponse
   */
  fines: Fine
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof FileManagerUinResponse
   */
  requestId: string
}

/**
 * @export
 * @enum {string}
 */
export enum FileManagerUinResponseErrorCodeEnum {
  NUMBER_0 = 0,
  NUMBER_1000 = 1000,
  NUMBER_48026 = 48026,
  NUMBER_48027 = 48027,
  NUMBER_48028 = 48028,
  NUMBER_48029 = 48029,
  NUMBER_48030 = 48030,
}

/**
 *
 * @export
 * @interface Fine
 */
export interface Fine {
  /**
   * Список ссылок на фото Штрафов ГИБДД
   * @type {Array<FinePhoto>}
   * @memberof Fine
   */
  photos?: Array<FinePhoto>
  /**
   * Идентификатор нарушения
   * @type {string}
   * @memberof Fine
   */
  uin?: string
}
/**
 *
 * @export
 * @interface FinePhoto
 */
export interface FinePhoto {
  /**
   * Идентификатор фотографии
   * @type {string}
   * @memberof FinePhoto
   */
  fileId?: string
  /**
   * Ссылка на оригинал фотографии
   * @type {string}
   * @memberof FinePhoto
   */
  originalUrl?: string
  /**
   * Ссылка на превью фотографии
   * @type {string}
   * @memberof FinePhoto
   */
  previewUrl?: string
}
/**
 * Запрос баланса парковок.
 * @export
 * @interface GetBalanceRequest
 */
export interface GetBalanceRequest {
  /**
   * Идентификатор абонента в системе ТСП
   * @type {string}
   * @memberof GetBalanceRequest
   */
  account: string
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof GetBalanceRequest
   */
  requestId: string
}
/**
 * Ответ на запрос баланса парковок.
 * @export
 * @interface GetBalanceResponse
 */
export interface GetBalanceResponse {
  /**
   * Баланс лицевого счета Моспаркинг
   * @type {number}
   * @memberof GetBalanceResponse
   */
  balance: number
  /**
   * Код ошибки возникшей при обработке запроса
   * @type {number}
   * @memberof GetBalanceResponse
   */
  errorCode: GetBalanceResponseErrorCodeEnum
  /**
   * Описание ошибки возникшей при обработке запроса
   * @type {string}
   * @memberof GetBalanceResponse
   */
  errorMessage?: string
  /**
   * Идентификатор запроса
   * @type {string}
   * @memberof GetBalanceResponse
   */
  requestId: string
}

/**
 * @export
 * @enum {string}
 */
export enum GetBalanceResponseErrorCodeEnum {
  NUMBER_0 = 0,
  NUMBER_1000 = 1000,
  NUMBER_48026 = 48026,
  NUMBER_48027 = 48027,
  NUMBER_48028 = 48028,
  NUMBER_48029 = 48029,
  NUMBER_48030 = 48030,
}

/**
 * Запрос информации о переданных показаниях приборов учета по идентификатору бизнес-процесса
 * @export
 * @interface GetMetersValuesByProcessRequest
 */
export interface GetMetersValuesByProcessRequest {
  /**
   * Идентификатор бизнес-процесса
   * @type {string}
   * @memberof GetMetersValuesByProcessRequest
   */
  processId: string
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof GetMetersValuesByProcessRequest
   */
  requestId: string
}
/**
 * Ответ на запрос передачи новых показаний приборов учета поставщику услуг
 * @export
 * @interface GetMetersValuesResponse
 */
export interface GetMetersValuesResponse {
  /**
   * Код ошибки возникшей при обработке запроса
   * @type {number}
   * @memberof GetMetersValuesResponse
   */
  errorCode: GetMetersValuesResponseErrorCodeEnum
  /**
   * Описание ошибки возникшей при обработке запроса
   * @type {string}
   * @memberof GetMetersValuesResponse
   */
  errorMessage?: string
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof GetMetersValuesResponse
   */
  requestId: string
  /**
   * Массив с информацией о потребленных клиентом услугах (новые показания приборов учета клиента)
   * @type {Array<ServiceDetail>}
   * @memberof GetMetersValuesResponse
   */
  serviceDetails: Array<ServiceDetail>
}

/**
 * @export
 * @enum {string}
 */
export enum GetMetersValuesResponseErrorCodeEnum {
  NUMBER_0 = 0,
  NUMBER_1000 = 1000,
  NUMBER_48026 = 48026,
  NUMBER_48027 = 48027,
  NUMBER_48028 = 48028,
  NUMBER_48029 = 48029,
  NUMBER_48030 = 48030,
}

/**
 * Запрос на поиск подписок
 * @export
 * @interface GetSubscriptionByFilterRequest
 */
export interface GetSubscriptionByFilterRequest {
  /**
   * Тип начисления (EPD/FINES/TAX...)
   * @type {Array<string>}
   * @memberof GetSubscriptionByFilterRequest
   */
  chargeTypes?: Array<string>
  /**
   * Максимальная дата искомой подписки
   * @type {string}
   * @memberof GetSubscriptionByFilterRequest
   */
  leCreatedTime?: string
  /**
   * Максимальная сумма подписок
   * @type {number}
   * @memberof GetSubscriptionByFilterRequest
   */
  leMaxSumAutoPay?: number
  /**
   * Минимальная дата искомой подписки
   * @type {string}
   * @memberof GetSubscriptionByFilterRequest
   */
  meCreatedTime?: string
  /**
   * Минимальная сумма подписок
   * @type {number}
   * @memberof GetSubscriptionByFilterRequest
   */
  meMaxSumAutoPay?: number
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof GetSubscriptionByFilterRequest
   */
  requestId: string
  /**
   * Идентификатор типа платежа ПЦ ЭК
   * @type {Array<string>}
   * @memberof GetSubscriptionByFilterRequest
   */
  serviceIds?: Array<string>
  /**
   * Состояние подписки (ACTIVE/DISABLED/DELETED
   * @type {Array<string>}
   * @memberof GetSubscriptionByFilterRequest
   */
  states?: Array<string>
  /**
   * Параметры запроса по подписке
   * @type {Array<string>}
   * @memberof GetSubscriptionByFilterRequest
   */
  subscriptionParamKeysAND?: Array<string>
  /**
   * Параметры запроса по подписке
   * @type {Array<string>}
   * @memberof GetSubscriptionByFilterRequest
   */
  subscriptionParamKeysOR?: Array<string>
  /**
   * Тип подписки (AUTO_PAYMENT/WITH_CONFIRM
   * @type {Array<string>}
   * @memberof GetSubscriptionByFilterRequest
   */
  types?: Array<string>
  /**
   * Идентификатор кошелька в ПЦ ЭК
   * @type {Array<string>}
   * @memberof GetSubscriptionByFilterRequest
   */
  userIds?: Array<string>
}
/**
 * Ответ на поиск подписок
 * @export
 * @interface GetSubscriptionByFilterResponse
 */
export interface GetSubscriptionByFilterResponse {
  /**
   * Текущая страница с подписками
   * @type {number}
   * @memberof GetSubscriptionByFilterResponse
   */
  currentPage?: number
  /**
   * Код ошибки возникшей при обработке запроса
   * @type {number}
   * @memberof GetSubscriptionByFilterResponse
   */
  errorCode: GetSubscriptionByFilterResponseErrorCodeEnum
  /**
   * Описание ошибки возникшей при обработке запроса
   * @type {string}
   * @memberof GetSubscriptionByFilterResponse
   */
  errorMessage?: string
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof GetSubscriptionByFilterResponse
   */
  requestId: string
  /**
   * Подписки
   * @type {Array<SubscriptionDocument>}
   * @memberof GetSubscriptionByFilterResponse
   */
  subscriptions?: Array<SubscriptionDocument>
  /**
   * Количество найденных подписок всего
   * @type {number}
   * @memberof GetSubscriptionByFilterResponse
   */
  totalItems?: number
  /**
   * Количество страниц с подписками
   * @type {number}
   * @memberof GetSubscriptionByFilterResponse
   */
  totalPages?: number
}

/**
 * @export
 * @enum {string}
 */
export enum GetSubscriptionByFilterResponseErrorCodeEnum {
  NUMBER_0 = 0,
  NUMBER_1000 = 1000,
  NUMBER_48026 = 48026,
  NUMBER_48027 = 48027,
  NUMBER_48028 = 48028,
  NUMBER_48029 = 48029,
  NUMBER_48030 = 48030,
}

/**
 * Начисление
 * @export
 * @interface Invoice
 */
export interface Invoice {
  /**
   * Сумма к оплате в копейках
   * @type {string}
   * @memberof Invoice
   */
  amountToPay: string
  /**
   * Срок действия скидки
   * @type {string}
   * @memberof Invoice
   */
  discountDate?: string
  /**
   * Процент скидки
   * @type {string}
   * @memberof Invoice
   */
  discountSizePercent?: string
  /**
   * Сумма страховки в копейках
   * @type {string}
   * @memberof Invoice
   */
  insuranceAmount?: string
  /**
   * Сумма начисления в копейках
   * @type {string}
   * @memberof Invoice
   */
  invoiceAmount: string
  /**
   * Дата выставления начисления
   * @type {string}
   * @memberof Invoice
   */
  invoiceDate: string
  /**
   * УИН
   * @type {string}
   * @memberof Invoice
   */
  invoiceId: string
  /**
   * Наименование получателя платежа
   * @type {boolean}
   * @memberof Invoice
   */
  isRevoked: boolean
  /**
   * Наименование получателя платежа
   * @type {string}
   * @memberof Invoice
   */
  paymentStatus: InvoicePaymentStatusEnum
  /**
   * Назначение
   * @type {string}
   * @memberof Invoice
   */
  purpose: string
  /**
   * Наименование получателя платежа
   * @type {string}
   * @memberof Invoice
   */
  receiverName: string
  /**
   * Наименование получателя платежа
   * @type {string}
   * @memberof Invoice
   */
  validUntil: string
}

/**
 * @export
 * @enum {string}
 */
export enum InvoicePaymentStatusEnum {
  DEPOSITED = 'DEPOSITED',
  NOTPAYED = 'NOT_PAYED',
  ADVANCEDEPOSITED = 'ADVANCE_DEPOSITED',
}

/**
 * Параметры поиска начислений, помимо chargeType должен присутствовать по крайней мере один из идентификаторов плательщика (Passport, birthCertificate, SNILS, INN, VU, STS, payerCode, UIN, payerIdFL, payerIdUL и т.д.) в виде \"idName\": \"idValue\".
 * @export
 * @interface ListSearchParameters
 */
export interface ListSearchParameters {
  /**
   * Тип начисления (не указывать для поиска по всем типам начисления)
   * @type {string}
   * @memberof ListSearchParameters
   */
  chargeType?: ListSearchParametersChargeTypeEnum
}

/**
 * @export
 * @enum {string}
 */
export enum ListSearchParametersChargeTypeEnum {
  EPD = 'EPD',
  DOGM = 'DOGM',
  FINES = 'FINES',
  TRANSPORT = 'TRANSPORT',
  FSSP = 'FSSP',
  TAX = 'TAX',
  REGIONEPD = 'REGION_EPD',
  TAXOTHER = 'TAX_OTHER',
  ALLFINES = 'ALLFINES',
  MGTS = 'MGTS',
  FASTINVOICE = 'FAST_INVOICE',
  GISGKH = 'GIS_GKH',
  PARKINGBALANCE = 'PARKING_BALANCE',
  ALL = 'ALL',
  MTSCREDIT = 'MTS_CREDIT',
}

/**
 * Запрос получения отклика на предложение
 * @export
 * @interface NotifyResultRequest
 */
export interface NotifyResultRequest {
  /**
   *
   * @type {Aoc}
   * @memberof NotifyResultRequest
   */
  aoc: Aoc
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof NotifyResultRequest
   */
  requestId: string
}
/**
 * Ответ на запрос получения отклика на предложение
 * @export
 * @interface NotifyResultResponse
 */
export interface NotifyResultResponse {
  /**
   * Код ошибки возникшей при обработке запроса
   * @type {number}
   * @memberof NotifyResultResponse
   */
  errorCode: NotifyResultResponseErrorCodeEnum
  /**
   * Описание ошибки возникшей при обработке запроса
   * @type {string}
   * @memberof NotifyResultResponse
   */
  errorMessage?: string
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof NotifyResultResponse
   */
  requestId: string
}

/**
 * @export
 * @enum {string}
 */
export enum NotifyResultResponseErrorCodeEnum {
  NUMBER_0 = 0,
  NUMBER_1000 = 1000,
  NUMBER_48026 = 48026,
  NUMBER_48027 = 48027,
  NUMBER_48028 = 48028,
  NUMBER_48029 = 48029,
  NUMBER_48030 = 48030,
}

/**
 * Запрос на получение кода PS Store.
 * @export
 * @interface PSStoreCodeReceivingRequest
 */
export interface PSStoreCodeReceivingRequest {
  /**
   * ИД операции оплаты в ПХ
   * @type {string}
   * @memberof PSStoreCodeReceivingRequest
   */
  docExtId: string
  /**
   * Email клиента
   * @type {string}
   * @memberof PSStoreCodeReceivingRequest
   */
  email?: string
  /**
   * Номер телефона клиента
   * @type {string}
   * @memberof PSStoreCodeReceivingRequest
   */
  phone: string
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof PSStoreCodeReceivingRequest
   */
  requestId: string
}
/**
 *
 * @export
 * @interface ParamsAutopayment
 */
export interface ParamsAutopayment {
  /**
   * Платежный инструмент клиента ПЦ ЭК, который нужно использовать для автоматической оплаты начислений
   * @type {string}
   * @memberof ParamsAutopayment
   */
  bindingId?: string
  /**
   * День месяца, до которого следует отложить оплату поступившего счета. Если параметр отсутствует – оплачивать счета сразу после поступления. Число от 1 до 31.
   * @type {number}
   * @memberof ParamsAutopayment
   */
  paymentDay?: number
}
/**
 * Запрос статуса начислений в списке оплаченных
 * @export
 * @interface PayStatusRequest
 */
export interface PayStatusRequest {
  /**
   * Тип начисления
   * @type {string}
   * @memberof PayStatusRequest
   */
  chargeType?: string
  /**
   * УИН счета, по которому запрашивается статус оплаты
   * @type {string}
   * @memberof PayStatusRequest
   */
  invoiceId?: string
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof PayStatusRequest
   */
  requestId: string
}
/**
 * Ответ на запрос статуса начислений в списке оплаченных
 * @export
 * @interface PayStatusResponse
 */
export interface PayStatusResponse {
  /**
   * Код ошибки возникшей при обработке запроса
   * @type {number}
   * @memberof PayStatusResponse
   */
  errorCode: PayStatusResponseErrorCodeEnum
  /**
   * Описание ошибки возникшей при обработке запроса
   * @type {string}
   * @memberof PayStatusResponse
   */
  errorMessage?: string
  /**
   * Статус оплаты счета в сервисе МТС Деньги
   * @type {string}
   * @memberof PayStatusResponse
   */
  payStatus?: string
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof PayStatusResponse
   */
  requestId: string
}

/**
 * @export
 * @enum {string}
 */
export enum PayStatusResponseErrorCodeEnum {
  NUMBER_0 = 0,
  NUMBER_1000 = 1000,
  NUMBER_48026 = 48026,
  NUMBER_48027 = 48027,
  NUMBER_48028 = 48028,
  NUMBER_48029 = 48029,
  NUMBER_48030 = 48030,
}

/**
 * Параметр начисления
 * @export
 * @interface PaymentDetail
 */
export interface PaymentDetail {
  /**
   * Код бюджетной классификации платежа
   * @type {string}
   * @memberof PaymentDetail
   */
  KBK: string
  /**
   * OKTMO
   * @type {string}
   * @memberof PaymentDetail
   */
  OKTMO: string
  /**
   * Тип начисления
   * @type {string}
   * @memberof PaymentDetail
   */
  chargeType: string
  /**
   * УИН
   * @type {string}
   * @memberof PaymentDetail
   */
  invoiceId: string
  /**
   * ИНН или КИО плательщика
   * @type {string}
   * @memberof PaymentDetail
   */
  payerINN: string
  /**
   * ID плательщика
   * @type {string}
   * @memberof PaymentDetail
   */
  payerId: string
  /**
   * Назначение платежа
   * @type {string}
   * @memberof PaymentDetail
   */
  purpose: string
  /**
   * Код основания платежа
   * @type {string}
   * @memberof PaymentDetail
   */
  reasonPay: string
  /**
   * Дата требования в формате DD.MM.YYYY
   * @type {string}
   * @memberof PaymentDetail
   */
  reasonPayDocData: string
  /**
   * Номер документа
   * @type {string}
   * @memberof PaymentDetail
   */
  reasonPayDocNumber: string
  /**
   * Номер счета получателя
   * @type {string}
   * @memberof PaymentDetail
   */
  receiverAccount: string
  /**
   * БИК банка получателя, по справочнику БИК России
   * @type {string}
   * @memberof PaymentDetail
   */
  receiverBankBIC: string
  /**
   * ИНН или КИО получателя
   * @type {string}
   * @memberof PaymentDetail
   */
  receiverINN: string
  /**
   * КПП получателя
   * @type {string}
   * @memberof PaymentDetail
   */
  receiverKPP: string
  /**
   * Получатель
   * @type {string}
   * @memberof PaymentDetail
   */
  receiverName: string
  /**
   * Тип налогового платежа (110-е поле в ПП)
   * @type {string}
   * @memberof PaymentDetail
   */
  taxPayType: string
  /**
   * Срок уплаты по требованию
   * @type {string}
   * @memberof PaymentDetail
   */
  taxPeriod: string
  /**
   * Тип параметра
   * @type {string}
   * @memberof PaymentDetail
   */
  type: PaymentDetailTypeEnum
}

/**
 * @export
 * @enum {string}
 */
export enum PaymentDetailTypeEnum {
  STRING = 'STRING',
  LIST = 'LIST',
}

/**
 * Информация о платеже по начислению для ПЦ ЭК, помимо поля amount содержит прочие параметры платежа в виде \"name\": \"value\"
 * @export
 * @interface PaymentDetails
 */
export interface PaymentDetails {
  /**
   *
   * @type {Amount}
   * @memberof PaymentDetails
   */
  amount: Amount
}
/**
 * Информация о платеже по начислению для ПЦ ЭК, помимо поля amount содержит прочие параметры платежа в виде \"name\": \"value\"
 * @export
 * @interface PaymentDetailsRequest
 */
export interface PaymentDetailsRequest {
  /**
   *
   * @type {Amount}
   * @memberof PaymentDetailsRequest
   */
  amount?: Amount
}
/**
 * текущего баланса ЛС и показаний приборов учета клиента
 * @export
 * @interface ProviderBalanceAndMetersRequest
 */
export interface ProviderBalanceAndMetersRequest {
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof ProviderBalanceAndMetersRequest
   */
  requestId: string
  /**
   * Параметры поиска
   * @type {{ [key: string]: string; }}
   * @memberof ProviderBalanceAndMetersRequest
   */
  searchParams: { [key: string]: string }
}
/**
 * Ответ на запрос получения текущего баланса ЛС и показаний приборов учета клиента
 * @export
 * @interface ProviderBalanceAndMetersResponse
 */
export interface ProviderBalanceAndMetersResponse {
  /**
   * Номер лицевого счета клиента у провайдера
   * @type {string}
   * @memberof ProviderBalanceAndMetersResponse
   */
  accountNumber?: string
  /**
   * Текущий баланс ЛС клиента в минорных единицах измерения
   * @type {number}
   * @memberof ProviderBalanceAndMetersResponse
   */
  balanceAmount?: number
  /**
   * Детализация оказанных услуг, по которым был рассчитан текущий баланс ЛС
   * @type {Array<BalanceDetail>}
   * @memberof ProviderBalanceAndMetersResponse
   */
  balanceDetails?: Array<BalanceDetail>
  /**
   *
   * @type {ClientInfo}
   * @memberof ProviderBalanceAndMetersResponse
   */
  clientInfo?: ClientInfo
  /**
   * Код ошибки возникшей при обработке запроса
   * @type {number}
   * @memberof ProviderBalanceAndMetersResponse
   */
  errorCode: ProviderBalanceAndMetersResponseErrorCodeEnum
  /**
   * Описание ошибки возникшей при обработке запроса
   * @type {string}
   * @memberof ProviderBalanceAndMetersResponse
   */
  errorMessage?: string
  /**
   * Схема оплаты
   * @type {string}
   * @memberof ProviderBalanceAndMetersResponse
   */
  paymentScheme?: ProviderBalanceAndMetersResponsePaymentSchemeEnum
  /**
   * Идентификатор процесса, который используется при взаимодействии с МТСБ от поиска до оплаты
   * @type {string}
   * @memberof ProviderBalanceAndMetersResponse
   */
  processId: string
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof ProviderBalanceAndMetersResponse
   */
  requestId: string
  /**
   * Схема передачи новых показаний приборов учета
   * @type {string}
   * @memberof ProviderBalanceAndMetersResponse
   */
  sendingMeterValueScheme?: ProviderBalanceAndMetersResponseSendingMeterValueSchemeEnum
}

/**
 * @export
 * @enum {string}
 */
export enum ProviderBalanceAndMetersResponseErrorCodeEnum {
  NUMBER_0 = 0,
  NUMBER_1000 = 1000,
  NUMBER_48026 = 48026,
  NUMBER_48027 = 48027,
  NUMBER_48028 = 48028,
  NUMBER_48029 = 48029,
  NUMBER_48030 = 48030,
}
/**
 * @export
 * @enum {string}
 */
export enum ProviderBalanceAndMetersResponsePaymentSchemeEnum {
  FREE = 'FREE',
  FIX = 'FIX',
  CONDITIONALLYFREE = 'CONDITIONALLY_FREE',
  CHARGEONLY = 'CHARGE_ONLY',
}
/**
 * @export
 * @enum {string}
 */
export enum ProviderBalanceAndMetersResponseSendingMeterValueSchemeEnum {
  FREECOUNTERS = 'FREE_COUNTERS',
  COUNTERSWITHPAYMENT = 'COUNTERS_WITH_PAYMENT',
  NOCOUNTERS = 'NO_COUNTERS',
}

/**
 * Отклик, полученный от клиента
 * @export
 * @interface Reply
 */
export interface Reply {
  /**
   * Содержание отклика, полученного от клиента
   * @type {string}
   * @memberof Reply
   */
  content: string
}
/**
 * Запрос сохранения начислений в списке оплаченных
 * @export
 * @interface SavePaidChargeRequest
 */
export interface SavePaidChargeRequest {
  /**
   * Список начислений для сохранения
   * @type {Array<ChargeInfo>}
   * @memberof SavePaidChargeRequest
   */
  charges: Array<ChargeInfo>
  /**
   * Тип отметки об оплате, по умолчанию - CLIENT
   * @type {string}
   * @memberof SavePaidChargeRequest
   */
  markType?: SavePaidChargeRequestMarkTypeEnum
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof SavePaidChargeRequest
   */
  requestId: string
  /**
   * Идентификатор кошелька ПЦ ЭК
   * @type {string}
   * @memberof SavePaidChargeRequest
   */
  userId?: string
}

/**
 * @export
 * @enum {string}
 */
export enum SavePaidChargeRequestMarkTypeEnum {
  CLIENT = 'CLIENT',
  SYSTEM = 'SYSTEM',
}

/**
 * Ответ на запрос сохранения начислений в списке оплаченных
 * @export
 * @interface SavePaidChargeResponse
 */
export interface SavePaidChargeResponse {
  /**
   * Код ошибки возникшей при обработке запроса
   * @type {number}
   * @memberof SavePaidChargeResponse
   */
  errorCode: SavePaidChargeResponseErrorCodeEnum
  /**
   * Описание ошибки возникшей при обработке запроса
   * @type {string}
   * @memberof SavePaidChargeResponse
   */
  errorMessage?: string
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof SavePaidChargeResponse
   */
  requestId: string
}

/**
 * @export
 * @enum {string}
 */
export enum SavePaidChargeResponseErrorCodeEnum {
  NUMBER_0 = 0,
  NUMBER_1000 = 1000,
  NUMBER_48026 = 48026,
  NUMBER_48027 = 48027,
  NUMBER_48028 = 48028,
  NUMBER_48029 = 48029,
  NUMBER_48030 = 48030,
}

/**
 * Запрос поиска начислений по подпискам
 * @export
 * @interface SearchChargesBySubscriptionsRequest
 */
export interface SearchChargesBySubscriptionsRequest {
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof SearchChargesBySubscriptionsRequest
   */
  requestId: string
  /**
   * Идентификатор типа платежа ПЦ ЭК, параметр обязателен к заполнению, если в запросе отсутствует значение у параметра subscriptionId
   * @type {string}
   * @memberof SearchChargesBySubscriptionsRequest
   */
  serviceId?: string
  /**
   * Идентификатор подписки, параметр обязателен к заполнению, если в запросе отсутствуют userId и serviceId
   * @type {string}
   * @memberof SearchChargesBySubscriptionsRequest
   */
  subscriptionId?: string
  /**
   * Идентификатор кошелька в ПЦ ЭК, параметр обязателен к заполнению, если в запросе отсутствует значение у параметра subscriptionId
   * @type {string}
   * @memberof SearchChargesBySubscriptionsRequest
   */
  userId?: string
}
/**
 * Ответ на запрос поиска начислений по подписке
 * @export
 * @interface SearchChargesBySubscriptionsResponse
 */
export interface SearchChargesBySubscriptionsResponse {
  /**
   * Код ошибки возникшей при обработке запроса
   * @type {number}
   * @memberof SearchChargesBySubscriptionsResponse
   */
  errorCode: SearchChargesBySubscriptionsResponseErrorCodeEnum
  /**
   * Описание ошибки возникшей при обработке запроса
   * @type {string}
   * @memberof SearchChargesBySubscriptionsResponse
   */
  errorMessage?: string
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof SearchChargesBySubscriptionsResponse
   */
  requestId: string
  /**
   * Идентификатор заявки на поиск начисления (отсутствует только в случае ошибки, это не requestId из запроса
   * @type {string}
   * @memberof SearchChargesBySubscriptionsResponse
   */
  searchTicketId?: string
  /**
   * Идентификатор типа платежа ПЦ ЭК, по которому зарегистрирована подписка (отсутствует в случае ошибки)
   * @type {string}
   * @memberof SearchChargesBySubscriptionsResponse
   */
  serviceId?: string
}

/**
 * @export
 * @enum {string}
 */
export enum SearchChargesBySubscriptionsResponseErrorCodeEnum {
  NUMBER_0 = 0,
  NUMBER_1000 = 1000,
  NUMBER_48026 = 48026,
  NUMBER_48027 = 48027,
  NUMBER_48028 = 48028,
  NUMBER_48029 = 48029,
  NUMBER_48030 = 48030,
}

/**
 * Запрос поиска начислений
 * @export
 * @interface SearchChargesRequest
 */
export interface SearchChargesRequest {
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof SearchChargesRequest
   */
  requestId: string
  /**
   *
   * @type {SearchParameters}
   * @memberof SearchChargesRequest
   */
  searchParams: SearchParameters
}
/**
 * Ответ на запрос поиска начислений
 * @export
 * @interface SearchChargesResponse
 */
export interface SearchChargesResponse {
  /**
   * Код ошибки возникшей при обработке запроса
   * @type {number}
   * @memberof SearchChargesResponse
   */
  errorCode: SearchChargesResponseErrorCodeEnum
  /**
   * Описание ошибки возникшей при обработке запроса
   * @type {string}
   * @memberof SearchChargesResponse
   */
  errorMessage?: string
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof SearchChargesResponse
   */
  requestId: string
  /**
   * Идентификатор заявки на поиск начисления (отсутствует только в случае ошибки, это не requestId из запроса
   * @type {string}
   * @memberof SearchChargesResponse
   */
  searchTicketId?: string
}

/**
 * @export
 * @enum {string}
 */
export enum SearchChargesResponseErrorCodeEnum {
  NUMBER_0 = 0,
  NUMBER_1000 = 1000,
  NUMBER_48026 = 48026,
  NUMBER_48027 = 48027,
  NUMBER_48028 = 48028,
  NUMBER_48029 = 48029,
  NUMBER_48030 = 48030,
}

/**
 * Запрос результатов поиска начислений
 * @export
 * @interface SearchChargesResultRequest
 */
export interface SearchChargesResultRequest {
  /**
   * Признак необходимости скрывать начисления отмеченные как оплаченные (см. savePaidCharges), по умолчанию - false
   * @type {boolean}
   * @memberof SearchChargesResultRequest
   */
  hidePaid?: boolean
  /**
   * Признак снимающий фильтрацию уже оплаченных начислений , по умолчанию - false
   * @type {boolean}
   * @memberof SearchChargesResultRequest
   */
  noFilterCharges?: boolean
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof SearchChargesResultRequest
   */
  requestId: string
  /**
   * Идентификатор заявки на поиск начислений полученный при вызове searchCharges
   * @type {string}
   * @memberof SearchChargesResultRequest
   */
  searchTicketId: string
  /**
   * Идентификатор услуги (тип платежа). Deprecated. ПЦ ЭК
   * @type {string}
   * @memberof SearchChargesResultRequest
   */
  serviceId?: string
  /**
   * Идентификатор кошелька ПЦ ЭК (должен присутствовать, если hidePaid имеет значение true)
   * @type {string}
   * @memberof SearchChargesResultRequest
   */
  userId?: string
}
/**
 * Ответ на запрос результатов поиска начислений
 * @export
 * @interface SearchChargesResultResponse
 */
export interface SearchChargesResultResponse {
  /**
   * Найденные начисления (отсутствует только в случае ошибки)
   * @type {Array<EWCharge>}
   * @memberof SearchChargesResultResponse
   */
  charges?: Array<EWCharge>
  /**
   * Код ошибки возникшей при обработке запроса
   * @type {number}
   * @memberof SearchChargesResultResponse
   */
  errorCode: SearchChargesResultResponseErrorCodeEnum
  /**
   * Описание ошибки возникшей при обработке запроса
   * @type {string}
   * @memberof SearchChargesResultResponse
   */
  errorMessage?: string
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof SearchChargesResultResponse
   */
  requestId: string
}

/**
 * @export
 * @enum {string}
 */
export enum SearchChargesResultResponseErrorCodeEnum {
  NUMBER_0 = 0,
  NUMBER_1000 = 1000,
  NUMBER_48026 = 48026,
  NUMBER_48027 = 48027,
  NUMBER_48028 = 48028,
  NUMBER_48029 = 48029,
  NUMBER_48030 = 48030,
}

/**
 * Запрос итогов по неоплаченным счетам пользователя в разрезе подписок
 * @export
 * @interface SearchChargesTotalRequest
 */
export interface SearchChargesTotalRequest {
  /**
   * Признак необходимости скрывать начисления отмеченные как оплаченные (см. savePaidCharges), по умолчанию - false
   * @type {boolean}
   * @memberof SearchChargesTotalRequest
   */
  hidePaid?: boolean
  /**
   * Признак снимающий фильтрацию уже оплаченных начислений , по умолчанию - false
   * @type {boolean}
   * @memberof SearchChargesTotalRequest
   */
  noFilterCharges?: boolean
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof SearchChargesTotalRequest
   */
  requestId: string
  /**
   * Идентификатор кошелька ПЦ ЭК
   * @type {string}
   * @memberof SearchChargesTotalRequest
   */
  userId?: string
}
/**
 * Ответ на запрос итогов по неоплаченным счетам пользователя в разрезе подписок
 * @export
 * @interface SearchChargesTotalResponse
 */
export interface SearchChargesTotalResponse {
  /**
   * Суммарная задолженность по всем неоплаченным счетам. Возвращается в минорных денежных единицах (т.е. в копейках)
   * @type {number}
   * @memberof SearchChargesTotalResponse
   */
  amount?: number
  /**
   * Суммарная задолженность по всем неоплаченным счетам c учетом скидки. Возвращается в минорных денежных единицах (т.е. в копейках)
   * @type {number}
   * @memberof SearchChargesTotalResponse
   */
  amountWithDiscount?: number
  /**
   * Общее количество неоплаченных счетов по всем подпискам
   * @type {number}
   * @memberof SearchChargesTotalResponse
   */
  count?: number
  /**
   * Код ошибки возникшей при обработке запроса
   * @type {number}
   * @memberof SearchChargesTotalResponse
   */
  errorCode: SearchChargesTotalResponseErrorCodeEnum
  /**
   * Описание ошибки возникшей при обработке запроса
   * @type {string}
   * @memberof SearchChargesTotalResponse
   */
  errorMessage?: string
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof SearchChargesTotalResponse
   */
  requestId: string
  /**
   * Расчитанные итоги найденных начислений пользователя в разрезе подписок
   * @type {Array<SubscriptionChargesTotalDto>}
   * @memberof SearchChargesTotalResponse
   */
  subscriptions?: Array<SubscriptionChargesTotalDto>
}

/**
 * @export
 * @enum {string}
 */
export enum SearchChargesTotalResponseErrorCodeEnum {
  NUMBER_0 = 0,
  NUMBER_1000 = 1000,
  NUMBER_48026 = 48026,
  NUMBER_48027 = 48027,
  NUMBER_48028 = 48028,
  NUMBER_48029 = 48029,
  NUMBER_48030 = 48030,
}

/**
 * Запрос получения информации о начислении из лк ФНС
 * @export
 * @interface SearchFNSInvoicesRequest
 */
export interface SearchFNSInvoicesRequest {
  /**
   * Объект xml
   * @type {string}
   * @memberof SearchFNSInvoicesRequest
   */
  FNSData: string
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof SearchFNSInvoicesRequest
   */
  requestId: string
}
/**
 * Ответ на запрос получения информации о начислении из лк ФНС
 * @export
 * @interface SearchFNSInvoicesResponse
 */
export interface SearchFNSInvoicesResponse {
  /**
   * Код ошибки возникшей при обработке запроса
   * @type {number}
   * @memberof SearchFNSInvoicesResponse
   */
  errorCode: SearchFNSInvoicesResponseErrorCodeEnum
  /**
   * Описание ошибки возникшей при обработке запроса
   * @type {string}
   * @memberof SearchFNSInvoicesResponse
   */
  errorMessage?: string
  /**
   * Массив найденных начислений (отсутствует в случае ошибки)
   * @type {Array<FNSData>}
   * @memberof SearchFNSInvoicesResponse
   */
  payments?: Array<FNSData>
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof SearchFNSInvoicesResponse
   */
  requestId: string
}

/**
 * @export
 * @enum {string}
 */
export enum SearchFNSInvoicesResponseErrorCodeEnum {
  NUMBER_0 = 0,
  NUMBER_1000 = 1000,
  NUMBER_48026 = 48026,
  NUMBER_48027 = 48027,
  NUMBER_48028 = 48028,
  NUMBER_48029 = 48029,
  NUMBER_48030 = 48030,
}

/**
 * Запрос получения краткой информации о начислении
 * @export
 * @interface SearchInvoicesRequest
 */
export interface SearchInvoicesRequest {
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof SearchInvoicesRequest
   */
  requestId: string
  /**
   *
   * @type {ListSearchParameters}
   * @memberof SearchInvoicesRequest
   */
  searchParams: ListSearchParameters
}
/**
 * Ответ на запрос получения краткой информации о начислении
 * @export
 * @interface SearchInvoicesResponse
 */
export interface SearchInvoicesResponse {
  /**
   * Код ошибки возникшей при обработке запроса
   * @type {number}
   * @memberof SearchInvoicesResponse
   */
  errorCode: SearchInvoicesResponseErrorCodeEnum
  /**
   * Описание ошибки возникшей при обработке запроса
   * @type {string}
   * @memberof SearchInvoicesResponse
   */
  errorMessage?: string
  /**
   * Массив найденных начислений (отсутствует в случае ошибки)
   * @type {Array<Invoice>}
   * @memberof SearchInvoicesResponse
   */
  invoices?: Array<Invoice>
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof SearchInvoicesResponse
   */
  requestId: string
}

/**
 * @export
 * @enum {string}
 */
export enum SearchInvoicesResponseErrorCodeEnum {
  NUMBER_0 = 0,
  NUMBER_1000 = 1000,
  NUMBER_48026 = 48026,
  NUMBER_48027 = 48027,
  NUMBER_48028 = 48028,
  NUMBER_48029 = 48029,
  NUMBER_48030 = 48030,
}

/**
 * Параметры поиска начислений, помимо chargeType должен присутствовать по крайней мере один из идентификаторов плательщика (Passport, birthCertificate, SNILS, INN, VU, STS, payerCode, UIN, payerIdFL, payerIdUL и т.д.) в виде \"idName\": \"idValue\".
 * @export
 * @interface SearchParameters
 */
export interface SearchParameters {
  /**
   * Тип начисления (не указывать для поиска по всем типам начисления)
   * @type {string}
   * @memberof SearchParameters
   */
  chargeType?: SearchParametersChargeTypeEnum
}

/**
 * @export
 * @enum {string}
 */
export enum SearchParametersChargeTypeEnum {
  EPD = 'EPD',
  DOGM = 'DOGM',
  FINES = 'FINES',
  TRANSPORT = 'TRANSPORT',
  FSSP = 'FSSP',
  TAX = 'TAX',
  REGIONEPD = 'REGION_EPD',
  TAXOTHER = 'TAX_OTHER',
  ALLFINES = 'ALLFINES',
  MGTS = 'MGTS',
  FASTINVOICE = 'FAST_INVOICE',
  GISGKH = 'GIS_GKH',
  PARKINGBALANCE = 'PARKING_BALANCE',
  ALL = 'ALL',
  MTSCREDIT = 'MTS_CREDIT',
}

/**
 * Запрос получения всех начислений пользователя
 * @export
 * @interface SearchUserChargesRequest
 */
export interface SearchUserChargesRequest {
  /**
   * Признак необходимости скрывать начисления отмеченные как оплаченные (см. savePaidCharges), по умолчанию - false
   * @type {boolean}
   * @memberof SearchUserChargesRequest
   */
  hidePaid?: boolean
  /**
   * Признак снимающий фильтрацию уже оплаченных начислений , по умолчанию - false
   * @type {boolean}
   * @memberof SearchUserChargesRequest
   */
  noFilterCharges?: boolean
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof SearchUserChargesRequest
   */
  requestId: string
  /**
   * Идентификатор кошелька ПЦ ЭК
   * @type {string}
   * @memberof SearchUserChargesRequest
   */
  userId?: string
}
/**
 * Ответ на запрос получения всех начислений пользователя
 * @export
 * @interface SearchUserChargesResponse
 */
export interface SearchUserChargesResponse {
  /**
   * Код ошибки возникшей при обработке запроса
   * @type {number}
   * @memberof SearchUserChargesResponse
   */
  errorCode: SearchUserChargesResponseErrorCodeEnum
  /**
   * Описание ошибки возникшей при обработке запроса
   * @type {string}
   * @memberof SearchUserChargesResponse
   */
  errorMessage?: string
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof SearchUserChargesResponse
   */
  requestId: string
  /**
   * Найденные начисления пользователя в разрезе подписок
   * @type {Array<SubscriptionIdChargesDto>}
   * @memberof SearchUserChargesResponse
   */
  subscriptions?: Array<SubscriptionIdChargesDto>
}

/**
 * @export
 * @enum {string}
 */
export enum SearchUserChargesResponseErrorCodeEnum {
  NUMBER_0 = 0,
  NUMBER_1000 = 1000,
  NUMBER_48026 = 48026,
  NUMBER_48027 = 48027,
  NUMBER_48028 = 48028,
  NUMBER_48029 = 48029,
  NUMBER_48030 = 48030,
}

/**
 * Запрос информации о переданных показаниях приборов учета по идентификатору бизнес-процесса
 * @export
 * @interface SendMetersValueRequest
 */
export interface SendMetersValueRequest {
  /**
   * Идентификатор бизнес-процесса
   * @type {string}
   * @memberof SendMetersValueRequest
   */
  processId?: string
  /**
   * Идентификатор поставщика услуги, которому клиент передает новые показания приборов учета
   * @type {string}
   * @memberof SendMetersValueRequest
   */
  providerId?: string
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof SendMetersValueRequest
   */
  requestId: string
  /**
   * Схема передачи новых показаний приборов учета
   * @type {string}
   * @memberof SendMetersValueRequest
   */
  sendingMeterValueScheme: SendMetersValueRequestSendingMeterValueSchemeEnum
  /**
   * Массив с информацией о потребленных клиентом услугах (новые показания приборов учета клиента)
   * @type {Array<ServiceDetail>}
   * @memberof SendMetersValueRequest
   */
  serviceDetails: Array<ServiceDetail>
}

/**
 * @export
 * @enum {string}
 */
export enum SendMetersValueRequestSendingMeterValueSchemeEnum {
  FREECOUNTERS = 'FREE_COUNTERS',
  COUNTERSWITHPAYMENT = 'COUNTERS_WITH_PAYMENT',
  NOCOUNTERS = 'NO_COUNTERS',
}

/**
 * Ответ на запрос передачи новых показаний приборов учета поставщику услуг
 * @export
 * @interface SendMetersValueResponse
 */
export interface SendMetersValueResponse {
  /**
   * Код ошибки возникшей при обработке запроса
   * @type {number}
   * @memberof SendMetersValueResponse
   */
  errorCode: SendMetersValueResponseErrorCodeEnum
  /**
   * Описание ошибки возникшей при обработке запроса
   * @type {string}
   * @memberof SendMetersValueResponse
   */
  errorMessage?: string
  /**
   * Пересчитанный баланс ЛС клиента в минорных единицах измерения
   * @type {number}
   * @memberof SendMetersValueResponse
   */
  newBalanceAmount?: number
  /**
   * Идентификатор бизнес-процесса
   * @type {string}
   * @memberof SendMetersValueResponse
   */
  processId?: string
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof SendMetersValueResponse
   */
  requestId: string
}

/**
 * @export
 * @enum {string}
 */
export enum SendMetersValueResponseErrorCodeEnum {
  NUMBER_0 = 0,
  NUMBER_1000 = 1000,
  NUMBER_48026 = 48026,
  NUMBER_48027 = 48027,
  NUMBER_48028 = 48028,
  NUMBER_48029 = 48029,
  NUMBER_48030 = 48030,
}

/**
 * Информация о потребленных клиентом услугах (новые показания приборов учета клиента)
 * @export
 * @interface ServiceDetail
 */
export interface ServiceDetail {
  /**
   * Код услуги (Идентификатор прибора учета)
   * @type {string}
   * @memberof ServiceDetail
   */
  code: string
  /**
   * Название услуги (Название прибора учета)
   * @type {string}
   * @memberof ServiceDetail
   */
  name?: string
  /**
   * Номер прибора учета
   * @type {string}
   * @memberof ServiceDetail
   */
  number?: string
  /**
   * Идентификатор тарифа
   * @type {string}
   * @memberof ServiceDetail
   */
  tarifId?: string
  /**
   * Количество - новые показания прибора учета, которые ввел клиент
   * @type {string}
   * @memberof ServiceDetail
   */
  value: string
}
/**
 *
 * @export
 * @interface StateDetails
 */
export interface StateDetails {
  /**
   * Статус оплаты начисления в ГИС ГМП (РНиП)
   * @type {string}
   * @memberof StateDetails
   */
  gisPaidStatus: string
  /**
   * Признак того, что абонент отметил начисление как оплаченное
   * @type {boolean}
   * @memberof StateDetails
   */
  isPaidCharges: boolean
  /**
   * Признак возможности оплаты начисления
   * @type {boolean}
   * @memberof StateDetails
   */
  isPaymentPossible: boolean
  /**
   * Номер операции в ПЦ ЭК, которой оплатили начисление
   * @type {string}
   * @memberof StateDetails
   */
  mdOrder?: string
  /**
   * Причина невозможности оплаты начисления
   * @type {string}
   * @memberof StateDetails
   */
  reasonPaymentImpossible?: StateDetailsReasonPaymentImpossibleEnum
}

/**
 * @export
 * @enum {string}
 */
export enum StateDetailsReasonPaymentImpossibleEnum {
  PAID = 'PAID',
  EXPIRED = 'EXPIRED',
  REVOKED = 'REVOKED',
}

/**
 *
 * @export
 * @interface Subscription
 */
export interface Subscription {
  /**
   * Id автоплатежа в сервисе автоплатежей
   * @type {string}
   * @memberof Subscription
   */
  apId?: string
  /**
   * Максимальная сумма автоматической оплаты счетов
   * @type {number}
   * @memberof Subscription
   */
  maxSumAutoPay?: number
  /**
   * ФИО плательщика. Используется в случае оплаты счетов, найденных по подписке в автоматическом режиме: при оплате с помощью ответной СМС и если к подписке подключен АП
   * @type {string}
   * @memberof Subscription
   */
  payerName?: string
  /**
   * Идентификатор типа платежа ПЦ ЭК
   * @type {string}
   * @memberof Subscription
   */
  serviceId: string
  /**
   * Состояние подписки
   * @type {string}
   * @memberof Subscription
   */
  state: SubscriptionStateEnum
  /**
   * Идентификатор подписки
   * @type {string}
   * @memberof Subscription
   */
  subscriptionId: string
  /**
   * Название подписки
   * @type {string}
   * @memberof Subscription
   */
  subscriptionName: string
  /**
   *
   * @type {SearchParameters}
   * @memberof Subscription
   */
  subscriptionParams: SearchParameters
  /**
   * Тип подписки
   * @type {string}
   * @memberof Subscription
   */
  type: SubscriptionTypeEnum
  /**
   * Идентификатор кошелька в ПЦ ЭК
   * @type {string}
   * @memberof Subscription
   */
  userId: string
}

/**
 * @export
 * @enum {string}
 */
export enum SubscriptionStateEnum {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
  DELETED = 'DELETED',
}
/**
 * @export
 * @enum {string}
 */
export enum SubscriptionTypeEnum {
  AUTOPAYMENT = 'AUTO_PAYMENT',
  WITHCONFIRM = 'WITH_CONFIRM',
}

/**
 *
 * @export
 * @interface SubscriptionChargesTotalDto
 */
export interface SubscriptionChargesTotalDto {
  /**
   * Задолженность по неоплаченным счетам. Возвращается в минорных денежных единицах (т.е. в копейках)
   * @type {number}
   * @memberof SubscriptionChargesTotalDto
   */
  amount?: number
  /**
   * Задолженность по неоплаченным счетам c учетом скидки. Возвращается в минорных денежных единицах (т.е. в копейках)
   * @type {number}
   * @memberof SubscriptionChargesTotalDto
   */
  amountWithDiscount?: number
  /**
   * Тип начисления (не указывать для поиска по всем типам начисления)
   * @type {string}
   * @memberof SubscriptionChargesTotalDto
   */
  chargeType?: SubscriptionChargesTotalDtoChargeTypeEnum
  /**
   * Количество неоплаченных счетов
   * @type {number}
   * @memberof SubscriptionChargesTotalDto
   */
  count?: number
  /**
   * Идентификатор подписки
   * @type {string}
   * @memberof SubscriptionChargesTotalDto
   */
  id?: string
  /**
   * Название подписки
   * @type {string}
   * @memberof SubscriptionChargesTotalDto
   */
  name?: string
  /**
   * Параметры поиска начислений, помимо chargeType должен присутствовать по крайней мере один из идентификаторов плательщика (Passport, birthCertificate, SNILS, INN, VU, STS, payerCode, UIN, payerIdFL, payerIdUL и т.д.) в виде \"idName\": \"idValue\"
   * @type {object}
   * @memberof SubscriptionChargesTotalDto
   */
  params?: object
  /**
   * ФИО плательщика. Используется в случае оплаты счетов, найденных по подписке в автоматическом режиме: при оплате с помощью ответной СМС и если к подписке подключен АП
   * @type {string}
   * @memberof SubscriptionChargesTotalDto
   */
  payerName?: string
  /**
   * Идентификатор услуги (тип платежа). Deprecated. ПЦ ЭК
   * @type {string}
   * @memberof SubscriptionChargesTotalDto
   */
  serviceId?: string
}

/**
 * @export
 * @enum {string}
 */
export enum SubscriptionChargesTotalDtoChargeTypeEnum {
  EPD = 'EPD',
  DOGM = 'DOGM',
  FINES = 'FINES',
  TRANSPORT = 'TRANSPORT',
  FSSP = 'FSSP',
  TAX = 'TAX',
  REGIONEPD = 'REGION_EPD',
  TAXOTHER = 'TAX_OTHER',
  ALLFINES = 'ALLFINES',
  MGTS = 'MGTS',
  FASTINVOICE = 'FAST_INVOICE',
  GISGKH = 'GIS_GKH',
  PARKINGBALANCE = 'PARKING_BALANCE',
  ALL = 'ALL',
  MTSCREDIT = 'MTS_CREDIT',
}

/**
 *
 * @export
 * @interface SubscriptionDocument
 */
export interface SubscriptionDocument {
  /**
   *
   * @type {string}
   * @memberof SubscriptionDocument
   */
  apId?: string
  /**
   *
   * @type {string}
   * @memberof SubscriptionDocument
   */
  bindingId?: string
  /**
   *
   * @type {string}
   * @memberof SubscriptionDocument
   */
  createdBy?: string
  /**
   *
   * @type {string}
   * @memberof SubscriptionDocument
   */
  createdTime?: string
  /**
   *
   * @type {string}
   * @memberof SubscriptionDocument
   */
  id?: string
  /**
   *
   * @type {boolean}
   * @memberof SubscriptionDocument
   */
  isHidden?: boolean
  /**
   *
   * @type {string}
   * @memberof SubscriptionDocument
   */
  lastModifiedBy?: string
  /**
   *
   * @type {string}
   * @memberof SubscriptionDocument
   */
  lastModifiedTime?: string
  /**
   *
   * @type {string}
   * @memberof SubscriptionDocument
   */
  lastSearchTime?: string
  /**
   *
   * @type {number}
   * @memberof SubscriptionDocument
   */
  maxSumAutoPay?: number
  /**
   *
   * @type {string}
   * @memberof SubscriptionDocument
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof SubscriptionDocument
   */
  payerName?: string
  /**
   *
   * @type {number}
   * @memberof SubscriptionDocument
   */
  paymentDay?: number
  /**
   *
   * @type {SearchParameters}
   * @memberof SubscriptionDocument
   */
  searchParams?: SearchParameters
  /**
   *
   * @type {string}
   * @memberof SubscriptionDocument
   */
  serviceId?: string
  /**
   *
   * @type {string}
   * @memberof SubscriptionDocument
   */
  state?: SubscriptionDocumentStateEnum
  /**
   *
   * @type {string}
   * @memberof SubscriptionDocument
   */
  subscriptionId?: string
  /**
   *
   * @type {string}
   * @memberof SubscriptionDocument
   */
  type?: SubscriptionDocumentTypeEnum
  /**
   *
   * @type {string}
   * @memberof SubscriptionDocument
   */
  userId?: string
}

/**
 * @export
 * @enum {string}
 */
export enum SubscriptionDocumentStateEnum {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
  DELETED = 'DELETED',
}
/**
 * @export
 * @enum {string}
 */
export enum SubscriptionDocumentTypeEnum {
  AUTOPAYMENT = 'AUTO_PAYMENT',
  WITHCONFIRM = 'WITH_CONFIRM',
}

/**
 *
 * @export
 * @interface SubscriptionIdChargesDto
 */
export interface SubscriptionIdChargesDto {
  /**
   * Список начислений пользователя по данной подписке
   * @type {Array<EWCharge>}
   * @memberof SubscriptionIdChargesDto
   */
  charges?: Array<EWCharge>
  /**
   * Идентификатор подписки
   * @type {string}
   * @memberof SubscriptionIdChargesDto
   */
  id?: string
}
/**
 * Запрос списка подписок
 * @export
 * @interface SubscriptionListRequest
 */
export interface SubscriptionListRequest {
  /**
   * Идентификатор АП, подключенный к подписке
   * @type {string}
   * @memberof SubscriptionListRequest
   */
  apId?: string
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof SubscriptionListRequest
   */
  requestId: string
  /**
   * Идентификатор типа платежа ПЦ ЭК
   * @type {string}
   * @memberof SubscriptionListRequest
   */
  serviceId?: string
  /**
   * Состояние подписки
   * @type {string}
   * @memberof SubscriptionListRequest
   */
  state?: SubscriptionListRequestStateEnum
  /**
   * Идентификатор подписки на поиск начислений
   * @type {string}
   * @memberof SubscriptionListRequest
   */
  subscriptionId?: string
  /**
   * Тип подписки
   * @type {string}
   * @memberof SubscriptionListRequest
   */
  type?: SubscriptionListRequestTypeEnum
  /**
   * Идентификатор кошелька в ПЦ ЭК
   * @type {string}
   * @memberof SubscriptionListRequest
   */
  userId: string
}

/**
 * @export
 * @enum {string}
 */
export enum SubscriptionListRequestStateEnum {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
  DELETED = 'DELETED',
}
/**
 * @export
 * @enum {string}
 */
export enum SubscriptionListRequestTypeEnum {
  AUTOPAYMENT = 'AUTO_PAYMENT',
  WITHCONFIRM = 'WITH_CONFIRM',
}

/**
 * Ответ на запрос списка подписок
 * @export
 * @interface SubscriptionListResponse
 */
export interface SubscriptionListResponse {
  /**
   * Код ошибки возникшей при обработке запроса
   * @type {number}
   * @memberof SubscriptionListResponse
   */
  errorCode: SubscriptionListResponseErrorCodeEnum
  /**
   * Описание ошибки возникшей при обработке запроса
   * @type {string}
   * @memberof SubscriptionListResponse
   */
  errorMessage?: string
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof SubscriptionListResponse
   */
  requestId: string
  /**
   * Список найденных подписок (отсутствует только в случае ошибки)
   * @type {Array<Subscription>}
   * @memberof SubscriptionListResponse
   */
  subscriptions?: Array<Subscription>
}

/**
 * @export
 * @enum {string}
 */
export enum SubscriptionListResponseErrorCodeEnum {
  NUMBER_0 = 0,
  NUMBER_1000 = 1000,
  NUMBER_48026 = 48026,
  NUMBER_48027 = 48027,
  NUMBER_48028 = 48028,
  NUMBER_48029 = 48029,
  NUMBER_48030 = 48030,
}

/**
 * Запрос на изменение подписки
 * @export
 * @interface UpdateSubscriptionRequest
 */
export interface UpdateSubscriptionRequest {
  /**
   *
   * @type {ParamsAutopayment}
   * @memberof UpdateSubscriptionRequest
   */
  autoPaymentParams?: ParamsAutopayment
  /**
   * Максимальная сумма автоматической оплаты счетов
   * @type {number}
   * @memberof UpdateSubscriptionRequest
   */
  maxSumAutoPay?: number
  /**
   * ФИО плательщика. Используется в случае оплаты счетов, найденных по подписке в автоматическом режиме: при оплате с помощью ответной СМС и если к подписке подключен АП
   * @type {string}
   * @memberof UpdateSubscriptionRequest
   */
  payerName?: string
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof UpdateSubscriptionRequest
   */
  requestId: string
  /**
   * Идентификатор типа платежа ПЦ ЭК
   * @type {string}
   * @memberof UpdateSubscriptionRequest
   */
  serviceId?: string
  /**
   * Состояние подписки
   * @type {string}
   * @memberof UpdateSubscriptionRequest
   */
  state: UpdateSubscriptionRequestStateEnum
  /**
   * Идентификатор подписки (UUID)
   * @type {string}
   * @memberof UpdateSubscriptionRequest
   */
  subscriptionId: string
  /**
   * Название подписки (максимум 250 символов)
   * @type {string}
   * @memberof UpdateSubscriptionRequest
   */
  subscriptionName: string
  /**
   *
   * @type {SearchParameters}
   * @memberof UpdateSubscriptionRequest
   */
  subscriptionParams: SearchParameters
  /**
   * Тип подписки
   * @type {string}
   * @memberof UpdateSubscriptionRequest
   */
  type: UpdateSubscriptionRequestTypeEnum
  /**
   * Идентификатор кошелька в ПЦ ЭК
   * @type {string}
   * @memberof UpdateSubscriptionRequest
   */
  userId: string
}

/**
 * @export
 * @enum {string}
 */
export enum UpdateSubscriptionRequestStateEnum {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
  DELETED = 'DELETED',
}
/**
 * @export
 * @enum {string}
 */
export enum UpdateSubscriptionRequestTypeEnum {
  AUTOPAYMENT = 'AUTO_PAYMENT',
  WITHCONFIRM = 'WITH_CONFIRM',
}

/**
 * Ответ на запрос на изменение подписки
 * @export
 * @interface UpdateSubscriptionResponse
 */
export interface UpdateSubscriptionResponse {
  /**
   * Уникальный идентификатор подключаемого автоплатежа, если errorCode==0
   * @type {string}
   * @memberof UpdateSubscriptionResponse
   */
  apId?: string
  /**
   * Код ошибки возникшей при обработке запроса
   * @type {number}
   * @memberof UpdateSubscriptionResponse
   */
  errorCode: UpdateSubscriptionResponseErrorCodeEnum
  /**
   * Описание ошибки возникшей при обработке запроса
   * @type {string}
   * @memberof UpdateSubscriptionResponse
   */
  errorMessage?: string
  /**
   * Глобально уникальный идентификатор запроса
   * @type {string}
   * @memberof UpdateSubscriptionResponse
   */
  requestId: string
}

/**
 * @export
 * @enum {string}
 */
export enum UpdateSubscriptionResponseErrorCodeEnum {
  NUMBER_0 = 0,
  NUMBER_1000 = 1000,
  NUMBER_48026 = 48026,
  NUMBER_48027 = 48027,
  NUMBER_48028 = 48028,
  NUMBER_48029 = 48029,
  NUMBER_48030 = 48030,
}

/**
 * ChargeApi - axios parameter creator
 * @export
 */
export const ChargeApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Метод позволяет удалить метку у начисления конкретного пользователя в сервисе начисление как оплаченное, после этого оно будет появляться в результатах поиска при выставленном признаке \'Скрывать оплаченные начисления\'
     * @summary Запрос удаления метки у начисления как оплаченного
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {DeletePaidChargeRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePaidChargeUsingPOST(clientId: string, request: DeletePaidChargeRequest, options: any = {}): RequestArgs {
      // verify required parameter 'clientId' is not null or undefined
      if (clientId === null || clientId === undefined) {
        throw new RequiredError(
          'clientId',
          'Required parameter clientId was null or undefined when calling deletePaidChargeUsingPOST.'
        )
      }
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          'request',
          'Required parameter request was null or undefined when calling deletePaidChargeUsingPOST.'
        )
      }
      const localVarPath = `/b/ftb-charge-invoice/invoices/1.0/{clientId}/deletePaidCharges`.replace(
        `{${'clientId'}}`,
        encodeURIComponent(String(clientId))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof request !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(request !== undefined ? request : {})
        : request || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Метод возвращает дополнительную информацию о начислении
     * @summary Запрос детальной информации по начислению
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {ChargeDetailsRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChargeDetailsUsingPOST(clientId: string, request: ChargeDetailsRequest, options: any = {}): RequestArgs {
      // verify required parameter 'clientId' is not null or undefined
      if (clientId === null || clientId === undefined) {
        throw new RequiredError(
          'clientId',
          'Required parameter clientId was null or undefined when calling getChargeDetailsUsingPOST.'
        )
      }
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          'request',
          'Required parameter request was null or undefined when calling getChargeDetailsUsingPOST.'
        )
      }
      const localVarPath = `/b/ftb-charge-invoice/invoices/1.0/{clientId}/getChargeInfoDetails`.replace(
        `{${'clientId'}}`,
        encodeURIComponent(String(clientId))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof request !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(request !== undefined ? request : {})
        : request || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Метод возвращает начисления по всем подпискам пользователя
     * @summary Получение всех начислений для пользователя
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {SearchUserChargesRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChargesByUserIdUsingPOST(clientId: string, request: SearchUserChargesRequest, options: any = {}): RequestArgs {
      // verify required parameter 'clientId' is not null or undefined
      if (clientId === null || clientId === undefined) {
        throw new RequiredError(
          'clientId',
          'Required parameter clientId was null or undefined when calling getChargesByUserIdUsingPOST.'
        )
      }
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          'request',
          'Required parameter request was null or undefined when calling getChargesByUserIdUsingPOST.'
        )
      }
      const localVarPath = `/b/ftb-charge-invoice/invoices/1.0/{clientId}/getUserCharges`.replace(
        `{${'clientId'}}`,
        encodeURIComponent(String(clientId))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof request !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(request !== undefined ? request : {})
        : request || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Метод возвращает итоги по неоплаченным счетам пользователя в разрезе подписок
     * @summary Итоги по неоплаченным счетам пользователя в разрезе подписок
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {SearchChargesTotalRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChargesTotalsUsingPOST(clientId: string, request: SearchChargesTotalRequest, options: any = {}): RequestArgs {
      // verify required parameter 'clientId' is not null or undefined
      if (clientId === null || clientId === undefined) {
        throw new RequiredError(
          'clientId',
          'Required parameter clientId was null or undefined when calling getChargesTotalsUsingPOST.'
        )
      }
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          'request',
          'Required parameter request was null or undefined when calling getChargesTotalsUsingPOST.'
        )
      }
      const localVarPath = `/b/ftb-charge-invoice/invoices/1.0/{clientId}/getChargesTotals`.replace(
        `{${'clientId'}}`,
        encodeURIComponent(String(clientId))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof request !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(request !== undefined ? request : {})
        : request || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Метод предназначен для передачи сохраненных показаний приборов учета клиента при оплате
     * @summary Технологический запрос для передачи сохраненных показаний приборов учета клиента при оплате
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {GetMetersValuesByProcessRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMetersByProcessIdUsingPOST(
      clientId: string,
      request: GetMetersValuesByProcessRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'clientId' is not null or undefined
      if (clientId === null || clientId === undefined) {
        throw new RequiredError(
          'clientId',
          'Required parameter clientId was null or undefined when calling getMetersByProcessIdUsingPOST.'
        )
      }
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          'request',
          'Required parameter request was null or undefined when calling getMetersByProcessIdUsingPOST.'
        )
      }
      const localVarPath = `/b/ftb-charge-invoice/invoices/1.0/{clientId}/getMetersByProcessId`.replace(
        `{${'clientId'}}`,
        encodeURIComponent(String(clientId))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof request !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(request !== undefined ? request : {})
        : request || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Метод предназначен для получение баланса парковок Москвы.
     * @summary Запрос баланса парковок
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {GetBalanceRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getParkingBalanceUsingPOST(clientId: string, request: GetBalanceRequest, options: any = {}): RequestArgs {
      // verify required parameter 'clientId' is not null or undefined
      if (clientId === null || clientId === undefined) {
        throw new RequiredError(
          'clientId',
          'Required parameter clientId was null or undefined when calling getParkingBalanceUsingPOST.'
        )
      }
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          'request',
          'Required parameter request was null or undefined when calling getParkingBalanceUsingPOST.'
        )
      }
      const localVarPath = `/b/ftb-charge-invoice/invoices/1.0/{clientId}/getParkingBalance`.replace(
        `{${'clientId'}}`,
        encodeURIComponent(String(clientId))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof request !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(request !== undefined ? request : {})
        : request || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Метод позволяет узнать статус у начисления конкретного пользователя в сервисе начислений
     * @summary Запрос статуса у начисления оплаченного/неоплаченного
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {PayStatusRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPayStatusUsingPOST(clientId: string, request: PayStatusRequest, options: any = {}): RequestArgs {
      // verify required parameter 'clientId' is not null or undefined
      if (clientId === null || clientId === undefined) {
        throw new RequiredError(
          'clientId',
          'Required parameter clientId was null or undefined when calling getPayStatusUsingPOST.'
        )
      }
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          'request',
          'Required parameter request was null or undefined when calling getPayStatusUsingPOST.'
        )
      }
      const localVarPath = `/b/ftb-charge-invoice/invoices/1.0/{clientId}/getPayStatus`.replace(
        `{${'clientId'}}`,
        encodeURIComponent(String(clientId))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof request !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(request !== undefined ? request : {})
        : request || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Метод инициирует валидацию подписаннного xml документа. В случае успеха, возвращает массив найденных начислений.
     * @summary Запрос создания начислений из личного кабинета клиента ФНС
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {SearchFNSInvoicesRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaymentsByFNSInvoicesUsingPOST(
      clientId: string,
      request: SearchFNSInvoicesRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'clientId' is not null or undefined
      if (clientId === null || clientId === undefined) {
        throw new RequiredError(
          'clientId',
          'Required parameter clientId was null or undefined when calling getPaymentsByFNSInvoicesUsingPOST.'
        )
      }
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          'request',
          'Required parameter request was null or undefined when calling getPaymentsByFNSInvoicesUsingPOST.'
        )
      }
      const localVarPath = `/b/ftb-charge-invoice/invoices/1.0/{clientId}/getPaymentsByFNSInvoices`.replace(
        `{${'clientId'}}`,
        encodeURIComponent(String(clientId))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof request !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(request !== undefined ? request : {})
        : request || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Метод возвращает текущий баланс ЛС клиента у поставщика услуг, показания последних приборов учета,переданные клиентом поставщику услуг (актуально для ЖКУ)
     * @summary Запрос получения текущего баланса ЛС и показаний приборов учета клиента у поставщика услуг
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {ProviderBalanceAndMetersRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProviderBalanceWithCountersUsingPOST(
      clientId: string,
      request: ProviderBalanceAndMetersRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'clientId' is not null or undefined
      if (clientId === null || clientId === undefined) {
        throw new RequiredError(
          'clientId',
          'Required parameter clientId was null or undefined when calling getProviderBalanceWithCountersUsingPOST.'
        )
      }
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          'request',
          'Required parameter request was null or undefined when calling getProviderBalanceWithCountersUsingPOST.'
        )
      }
      const localVarPath = `/b/ftb-charge-invoice/invoices/1.0/{clientId}/getProviderBalanceWithCounters`.replace(
        `{${'clientId'}}`,
        encodeURIComponent(String(clientId))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof request !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(request !== undefined ? request : {})
        : request || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Метод возвращает результат поиска начислений по идентификатору заявки на поиск полученной при успешном вызове searchCharges
     * @summary Запрос результатов поиска начислений
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {SearchChargesResultRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSearchChargesResultUsingPOST(
      clientId: string,
      request: SearchChargesResultRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'clientId' is not null or undefined
      if (clientId === null || clientId === undefined) {
        throw new RequiredError(
          'clientId',
          'Required parameter clientId was null or undefined when calling getSearchChargesResultUsingPOST.'
        )
      }
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          'request',
          'Required parameter request was null or undefined when calling getSearchChargesResultUsingPOST.'
        )
      }
      const localVarPath = `/b/ftb-charge-invoice/invoices/1.0/{clientId}/getSearchChargesResult`.replace(
        `{${'clientId'}}`,
        encodeURIComponent(String(clientId))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof request !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(request !== undefined ? request : {})
        : request || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Метод возвращает результат выполнения команды: принят запрос (0) или нет
     * @summary Принять уведомление о получении отклика на предложение
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {NotifyResultRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notifySMSAocResultUsingPOST(clientId: string, request: NotifyResultRequest, options: any = {}): RequestArgs {
      // verify required parameter 'clientId' is not null or undefined
      if (clientId === null || clientId === undefined) {
        throw new RequiredError(
          'clientId',
          'Required parameter clientId was null or undefined when calling notifySMSAocResultUsingPOST.'
        )
      }
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          'request',
          'Required parameter request was null or undefined when calling notifySMSAocResultUsingPOST.'
        )
      }
      const localVarPath = `/b/ftb-charge-invoice/invoices/1.0/{clientId}/notifyResult`.replace(
        `{${'clientId'}}`,
        encodeURIComponent(String(clientId))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof request !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(request !== undefined ? request : {})
        : request || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Метод позволяет сохранить в сервисе начисление конкретного пользователя как оплаченное, после этого оно не будет появляться в результатах поиска при выставленном признаке \'Скрывать оплаченные начисления\'
     * @summary Запрос сохранения начисления как оплаченного
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {SavePaidChargeRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    savePaidChargeUsingPOST(clientId: string, request: SavePaidChargeRequest, options: any = {}): RequestArgs {
      // verify required parameter 'clientId' is not null or undefined
      if (clientId === null || clientId === undefined) {
        throw new RequiredError(
          'clientId',
          'Required parameter clientId was null or undefined when calling savePaidChargeUsingPOST.'
        )
      }
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          'request',
          'Required parameter request was null or undefined when calling savePaidChargeUsingPOST.'
        )
      }
      const localVarPath = `/b/ftb-charge-invoice/invoices/1.0/{clientId}/savePaidCharges`.replace(
        `{${'clientId'}}`,
        encodeURIComponent(String(clientId))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof request !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(request !== undefined ? request : {})
        : request || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Метод инициирует поиск начислений по сохранённым ранее активным подпискам пользователя, возвращая в ответ, в случае успеха, идентификатор поиска, по которому в последствии можно запросить результаты (см. getSearchChargesResult)
     * @summary Запрос поиска начислений по подпискам
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {SearchChargesBySubscriptionsRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchChargesBySubscriptionsUsingPOST(
      clientId: string,
      request: SearchChargesBySubscriptionsRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'clientId' is not null or undefined
      if (clientId === null || clientId === undefined) {
        throw new RequiredError(
          'clientId',
          'Required parameter clientId was null or undefined when calling searchChargesBySubscriptionsUsingPOST.'
        )
      }
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          'request',
          'Required parameter request was null or undefined when calling searchChargesBySubscriptionsUsingPOST.'
        )
      }
      const localVarPath = `/b/ftb-charge-invoice/invoices/1.0/{clientId}/searchChargesBySubscriptions`.replace(
        `{${'clientId'}}`,
        encodeURIComponent(String(clientId))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof request !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(request !== undefined ? request : {})
        : request || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Метод инициирует поиск начислений, возвращая в ответ, в случае успеха, идентификатор поиска, по которому в последствии можно запросить результаты (см. getSearchChargesResult)
     * @summary Запрос поиска начислений
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {SearchChargesRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchChargesUsingPOST(clientId: string, request: SearchChargesRequest, options: any = {}): RequestArgs {
      // verify required parameter 'clientId' is not null or undefined
      if (clientId === null || clientId === undefined) {
        throw new RequiredError(
          'clientId',
          'Required parameter clientId was null or undefined when calling searchChargesUsingPOST.'
        )
      }
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          'request',
          'Required parameter request was null or undefined when calling searchChargesUsingPOST.'
        )
      }
      const localVarPath = `/b/ftb-charge-invoice/invoices/1.0/{clientId}/searchCharges`.replace(
        `{${'clientId'}}`,
        encodeURIComponent(String(clientId))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof request !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(request !== undefined ? request : {})
        : request || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Метод возвращает начисления независимо от статуса оплаты и актуальности начисления
     * @summary Запрос получения краткой информации о начислении
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {SearchInvoicesRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchInvoicesUsingPOST(clientId: string, request: SearchInvoicesRequest, options: any = {}): RequestArgs {
      // verify required parameter 'clientId' is not null or undefined
      if (clientId === null || clientId === undefined) {
        throw new RequiredError(
          'clientId',
          'Required parameter clientId was null or undefined when calling searchInvoicesUsingPOST.'
        )
      }
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          'request',
          'Required parameter request was null or undefined when calling searchInvoicesUsingPOST.'
        )
      }
      const localVarPath = `/b/ftb-charge-invoice/invoices/1.0/{clientId}/searchInvoices`.replace(
        `{${'clientId'}}`,
        encodeURIComponent(String(clientId))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof request !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(request !== undefined ? request : {})
        : request || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Метод предназначен для передачи новых показаний приборов учета поставщику услуг
     * @summary Запрос передачи новых показаний приборов учета поставщику услуг
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {SendMetersValueRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendMetersValueUsingPOST(clientId: string, request: SendMetersValueRequest, options: any = {}): RequestArgs {
      // verify required parameter 'clientId' is not null or undefined
      if (clientId === null || clientId === undefined) {
        throw new RequiredError(
          'clientId',
          'Required parameter clientId was null or undefined when calling sendMetersValueUsingPOST.'
        )
      }
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          'request',
          'Required parameter request was null or undefined when calling sendMetersValueUsingPOST.'
        )
      }
      const localVarPath = `/b/ftb-charge-invoice/invoices/1.0/{clientId}/sendMetersValue`.replace(
        `{${'clientId'}}`,
        encodeURIComponent(String(clientId))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof request !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(request !== undefined ? request : {})
        : request || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ChargeApi - functional programming interface
 * @export
 */
export const ChargeApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Метод позволяет удалить метку у начисления конкретного пользователя в сервисе начисление как оплаченное, после этого оно будет появляться в результатах поиска при выставленном признаке \'Скрывать оплаченные начисления\'
     * @summary Запрос удаления метки у начисления как оплаченного
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {DeletePaidChargeRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePaidChargeUsingPOST(
      clientId: string,
      request: DeletePaidChargeRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeletePaidChargeResponse> {
      const localVarAxiosArgs = ChargeApiAxiosParamCreator(configuration).deletePaidChargeUsingPOST(
        clientId,
        request,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Метод возвращает дополнительную информацию о начислении
     * @summary Запрос детальной информации по начислению
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {ChargeDetailsRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChargeDetailsUsingPOST(
      clientId: string,
      request: ChargeDetailsRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChargeDetailsResponse> {
      const localVarAxiosArgs = ChargeApiAxiosParamCreator(configuration).getChargeDetailsUsingPOST(
        clientId,
        request,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Метод возвращает начисления по всем подпискам пользователя
     * @summary Получение всех начислений для пользователя
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {SearchUserChargesRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChargesByUserIdUsingPOST(
      clientId: string,
      request: SearchUserChargesRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchUserChargesResponse> {
      const localVarAxiosArgs = ChargeApiAxiosParamCreator(configuration).getChargesByUserIdUsingPOST(
        clientId,
        request,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Метод возвращает итоги по неоплаченным счетам пользователя в разрезе подписок
     * @summary Итоги по неоплаченным счетам пользователя в разрезе подписок
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {SearchChargesTotalRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChargesTotalsUsingPOST(
      clientId: string,
      request: SearchChargesTotalRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchChargesTotalResponse> {
      const localVarAxiosArgs = ChargeApiAxiosParamCreator(configuration).getChargesTotalsUsingPOST(
        clientId,
        request,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Метод предназначен для передачи сохраненных показаний приборов учета клиента при оплате
     * @summary Технологический запрос для передачи сохраненных показаний приборов учета клиента при оплате
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {GetMetersValuesByProcessRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMetersByProcessIdUsingPOST(
      clientId: string,
      request: GetMetersValuesByProcessRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMetersValuesResponse> {
      const localVarAxiosArgs = ChargeApiAxiosParamCreator(configuration).getMetersByProcessIdUsingPOST(
        clientId,
        request,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Метод предназначен для получение баланса парковок Москвы.
     * @summary Запрос баланса парковок
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {GetBalanceRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getParkingBalanceUsingPOST(
      clientId: string,
      request: GetBalanceRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBalanceResponse> {
      const localVarAxiosArgs = ChargeApiAxiosParamCreator(configuration).getParkingBalanceUsingPOST(
        clientId,
        request,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Метод позволяет узнать статус у начисления конкретного пользователя в сервисе начислений
     * @summary Запрос статуса у начисления оплаченного/неоплаченного
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {PayStatusRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPayStatusUsingPOST(
      clientId: string,
      request: PayStatusRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PayStatusResponse> {
      const localVarAxiosArgs = ChargeApiAxiosParamCreator(configuration).getPayStatusUsingPOST(
        clientId,
        request,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Метод инициирует валидацию подписаннного xml документа. В случае успеха, возвращает массив найденных начислений.
     * @summary Запрос создания начислений из личного кабинета клиента ФНС
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {SearchFNSInvoicesRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaymentsByFNSInvoicesUsingPOST(
      clientId: string,
      request: SearchFNSInvoicesRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchFNSInvoicesResponse> {
      const localVarAxiosArgs = ChargeApiAxiosParamCreator(configuration).getPaymentsByFNSInvoicesUsingPOST(
        clientId,
        request,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Метод возвращает текущий баланс ЛС клиента у поставщика услуг, показания последних приборов учета,переданные клиентом поставщику услуг (актуально для ЖКУ)
     * @summary Запрос получения текущего баланса ЛС и показаний приборов учета клиента у поставщика услуг
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {ProviderBalanceAndMetersRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProviderBalanceWithCountersUsingPOST(
      clientId: string,
      request: ProviderBalanceAndMetersRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderBalanceAndMetersResponse> {
      const localVarAxiosArgs = ChargeApiAxiosParamCreator(configuration).getProviderBalanceWithCountersUsingPOST(
        clientId,
        request,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Метод возвращает результат поиска начислений по идентификатору заявки на поиск полученной при успешном вызове searchCharges
     * @summary Запрос результатов поиска начислений
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {SearchChargesResultRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSearchChargesResultUsingPOST(
      clientId: string,
      request: SearchChargesResultRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchChargesResultResponse> {
      const localVarAxiosArgs = ChargeApiAxiosParamCreator(configuration).getSearchChargesResultUsingPOST(
        clientId,
        request,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Метод возвращает результат выполнения команды: принят запрос (0) или нет
     * @summary Принять уведомление о получении отклика на предложение
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {NotifyResultRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notifySMSAocResultUsingPOST(
      clientId: string,
      request: NotifyResultRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotifyResultResponse> {
      const localVarAxiosArgs = ChargeApiAxiosParamCreator(configuration).notifySMSAocResultUsingPOST(
        clientId,
        request,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Метод позволяет сохранить в сервисе начисление конкретного пользователя как оплаченное, после этого оно не будет появляться в результатах поиска при выставленном признаке \'Скрывать оплаченные начисления\'
     * @summary Запрос сохранения начисления как оплаченного
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {SavePaidChargeRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    savePaidChargeUsingPOST(
      clientId: string,
      request: SavePaidChargeRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SavePaidChargeResponse> {
      const localVarAxiosArgs = ChargeApiAxiosParamCreator(configuration).savePaidChargeUsingPOST(
        clientId,
        request,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Метод инициирует поиск начислений по сохранённым ранее активным подпискам пользователя, возвращая в ответ, в случае успеха, идентификатор поиска, по которому в последствии можно запросить результаты (см. getSearchChargesResult)
     * @summary Запрос поиска начислений по подпискам
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {SearchChargesBySubscriptionsRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchChargesBySubscriptionsUsingPOST(
      clientId: string,
      request: SearchChargesBySubscriptionsRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchChargesBySubscriptionsResponse> {
      const localVarAxiosArgs = ChargeApiAxiosParamCreator(configuration).searchChargesBySubscriptionsUsingPOST(
        clientId,
        request,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Метод инициирует поиск начислений, возвращая в ответ, в случае успеха, идентификатор поиска, по которому в последствии можно запросить результаты (см. getSearchChargesResult)
     * @summary Запрос поиска начислений
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {SearchChargesRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchChargesUsingPOST(
      clientId: string,
      request: SearchChargesRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchChargesResponse> {
      const localVarAxiosArgs = ChargeApiAxiosParamCreator(configuration).searchChargesUsingPOST(
        clientId,
        request,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Метод возвращает начисления независимо от статуса оплаты и актуальности начисления
     * @summary Запрос получения краткой информации о начислении
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {SearchInvoicesRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchInvoicesUsingPOST(
      clientId: string,
      request: SearchInvoicesRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchInvoicesResponse> {
      const localVarAxiosArgs = ChargeApiAxiosParamCreator(configuration).searchInvoicesUsingPOST(
        clientId,
        request,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Метод предназначен для передачи новых показаний приборов учета поставщику услуг
     * @summary Запрос передачи новых показаний приборов учета поставщику услуг
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {SendMetersValueRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendMetersValueUsingPOST(
      clientId: string,
      request: SendMetersValueRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SendMetersValueResponse> {
      const localVarAxiosArgs = ChargeApiAxiosParamCreator(configuration).sendMetersValueUsingPOST(
        clientId,
        request,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * ChargeApi - factory interface
 * @export
 */
export const ChargeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     * Метод позволяет удалить метку у начисления конкретного пользователя в сервисе начисление как оплаченное, после этого оно будет появляться в результатах поиска при выставленном признаке \'Скрывать оплаченные начисления\'
     * @summary Запрос удаления метки у начисления как оплаченного
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {DeletePaidChargeRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePaidChargeUsingPOST(
      clientId: string,
      request: DeletePaidChargeRequest,
      options?: any
    ): AxiosPromise<DeletePaidChargeResponse> {
      return ChargeApiFp(configuration).deletePaidChargeUsingPOST(clientId, request, options)(axios, basePath)
    },
    /**
     * Метод возвращает дополнительную информацию о начислении
     * @summary Запрос детальной информации по начислению
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {ChargeDetailsRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChargeDetailsUsingPOST(
      clientId: string,
      request: ChargeDetailsRequest,
      options?: any
    ): AxiosPromise<ChargeDetailsResponse> {
      return ChargeApiFp(configuration).getChargeDetailsUsingPOST(clientId, request, options)(axios, basePath)
    },
    /**
     * Метод возвращает начисления по всем подпискам пользователя
     * @summary Получение всех начислений для пользователя
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {SearchUserChargesRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChargesByUserIdUsingPOST(
      clientId: string,
      request: SearchUserChargesRequest,
      options?: any
    ): AxiosPromise<SearchUserChargesResponse> {
      return ChargeApiFp(configuration).getChargesByUserIdUsingPOST(clientId, request, options)(axios, basePath)
    },
    /**
     * Метод возвращает итоги по неоплаченным счетам пользователя в разрезе подписок
     * @summary Итоги по неоплаченным счетам пользователя в разрезе подписок
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {SearchChargesTotalRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChargesTotalsUsingPOST(
      clientId: string,
      request: SearchChargesTotalRequest,
      options?: any
    ): AxiosPromise<SearchChargesTotalResponse> {
      return ChargeApiFp(configuration).getChargesTotalsUsingPOST(clientId, request, options)(axios, basePath)
    },
    /**
     * Метод предназначен для передачи сохраненных показаний приборов учета клиента при оплате
     * @summary Технологический запрос для передачи сохраненных показаний приборов учета клиента при оплате
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {GetMetersValuesByProcessRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMetersByProcessIdUsingPOST(
      clientId: string,
      request: GetMetersValuesByProcessRequest,
      options?: any
    ): AxiosPromise<GetMetersValuesResponse> {
      return ChargeApiFp(configuration).getMetersByProcessIdUsingPOST(clientId, request, options)(axios, basePath)
    },
    /**
     * Метод предназначен для получение баланса парковок Москвы.
     * @summary Запрос баланса парковок
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {GetBalanceRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getParkingBalanceUsingPOST(
      clientId: string,
      request: GetBalanceRequest,
      options?: any
    ): AxiosPromise<GetBalanceResponse> {
      return ChargeApiFp(configuration).getParkingBalanceUsingPOST(clientId, request, options)(axios, basePath)
    },
    /**
     * Метод позволяет узнать статус у начисления конкретного пользователя в сервисе начислений
     * @summary Запрос статуса у начисления оплаченного/неоплаченного
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {PayStatusRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPayStatusUsingPOST(clientId: string, request: PayStatusRequest, options?: any): AxiosPromise<PayStatusResponse> {
      return ChargeApiFp(configuration).getPayStatusUsingPOST(clientId, request, options)(axios, basePath)
    },
    /**
     * Метод инициирует валидацию подписаннного xml документа. В случае успеха, возвращает массив найденных начислений.
     * @summary Запрос создания начислений из личного кабинета клиента ФНС
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {SearchFNSInvoicesRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaymentsByFNSInvoicesUsingPOST(
      clientId: string,
      request: SearchFNSInvoicesRequest,
      options?: any
    ): AxiosPromise<SearchFNSInvoicesResponse> {
      return ChargeApiFp(configuration).getPaymentsByFNSInvoicesUsingPOST(clientId, request, options)(axios, basePath)
    },
    /**
     * Метод возвращает текущий баланс ЛС клиента у поставщика услуг, показания последних приборов учета,переданные клиентом поставщику услуг (актуально для ЖКУ)
     * @summary Запрос получения текущего баланса ЛС и показаний приборов учета клиента у поставщика услуг
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {ProviderBalanceAndMetersRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProviderBalanceWithCountersUsingPOST(
      clientId: string,
      request: ProviderBalanceAndMetersRequest,
      options?: any
    ): AxiosPromise<ProviderBalanceAndMetersResponse> {
      return ChargeApiFp(configuration).getProviderBalanceWithCountersUsingPOST(
        clientId,
        request,
        options
      )(axios, basePath)
    },
    /**
     * Метод возвращает результат поиска начислений по идентификатору заявки на поиск полученной при успешном вызове searchCharges
     * @summary Запрос результатов поиска начислений
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {SearchChargesResultRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSearchChargesResultUsingPOST(
      clientId: string,
      request: SearchChargesResultRequest,
      options?: any
    ): AxiosPromise<SearchChargesResultResponse> {
      return ChargeApiFp(configuration).getSearchChargesResultUsingPOST(clientId, request, options)(axios, basePath)
    },
    /**
     * Метод возвращает результат выполнения команды: принят запрос (0) или нет
     * @summary Принять уведомление о получении отклика на предложение
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {NotifyResultRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notifySMSAocResultUsingPOST(
      clientId: string,
      request: NotifyResultRequest,
      options?: any
    ): AxiosPromise<NotifyResultResponse> {
      return ChargeApiFp(configuration).notifySMSAocResultUsingPOST(clientId, request, options)(axios, basePath)
    },
    /**
     * Метод позволяет сохранить в сервисе начисление конкретного пользователя как оплаченное, после этого оно не будет появляться в результатах поиска при выставленном признаке \'Скрывать оплаченные начисления\'
     * @summary Запрос сохранения начисления как оплаченного
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {SavePaidChargeRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    savePaidChargeUsingPOST(
      clientId: string,
      request: SavePaidChargeRequest,
      options?: any
    ): AxiosPromise<SavePaidChargeResponse> {
      return ChargeApiFp(configuration).savePaidChargeUsingPOST(clientId, request, options)(axios, basePath)
    },
    /**
     * Метод инициирует поиск начислений по сохранённым ранее активным подпискам пользователя, возвращая в ответ, в случае успеха, идентификатор поиска, по которому в последствии можно запросить результаты (см. getSearchChargesResult)
     * @summary Запрос поиска начислений по подпискам
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {SearchChargesBySubscriptionsRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchChargesBySubscriptionsUsingPOST(
      clientId: string,
      request: SearchChargesBySubscriptionsRequest,
      options?: any
    ): AxiosPromise<SearchChargesBySubscriptionsResponse> {
      return ChargeApiFp(configuration).searchChargesBySubscriptionsUsingPOST(
        clientId,
        request,
        options
      )(axios, basePath)
    },
    /**
     * Метод инициирует поиск начислений, возвращая в ответ, в случае успеха, идентификатор поиска, по которому в последствии можно запросить результаты (см. getSearchChargesResult)
     * @summary Запрос поиска начислений
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {SearchChargesRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchChargesUsingPOST(
      clientId: string,
      request: SearchChargesRequest,
      options?: any
    ): AxiosPromise<SearchChargesResponse> {
      return ChargeApiFp(configuration).searchChargesUsingPOST(clientId, request, options)(axios, basePath)
    },
    /**
     * Метод возвращает начисления независимо от статуса оплаты и актуальности начисления
     * @summary Запрос получения краткой информации о начислении
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {SearchInvoicesRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchInvoicesUsingPOST(
      clientId: string,
      request: SearchInvoicesRequest,
      options?: any
    ): AxiosPromise<SearchInvoicesResponse> {
      return ChargeApiFp(configuration).searchInvoicesUsingPOST(clientId, request, options)(axios, basePath)
    },
    /**
     * Метод предназначен для передачи новых показаний приборов учета поставщику услуг
     * @summary Запрос передачи новых показаний приборов учета поставщику услуг
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {SendMetersValueRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendMetersValueUsingPOST(
      clientId: string,
      request: SendMetersValueRequest,
      options?: any
    ): AxiosPromise<SendMetersValueResponse> {
      return ChargeApiFp(configuration).sendMetersValueUsingPOST(clientId, request, options)(axios, basePath)
    },
  }
}

/**
 * ChargeApi - object-oriented interface
 * @export
 * @class ChargeApi
 * @extends {BaseAPI}
 */
export class ChargeApi extends BaseAPI {
  /**
   * Метод позволяет удалить метку у начисления конкретного пользователя в сервисе начисление как оплаченное, после этого оно будет появляться в результатах поиска при выставленном признаке \'Скрывать оплаченные начисления\'
   * @summary Запрос удаления метки у начисления как оплаченного
   * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
   * @param {DeletePaidChargeRequest} request Тело запроса
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChargeApi
   */
  public deletePaidChargeUsingPOST(clientId: string, request: DeletePaidChargeRequest, options?: any) {
    return ChargeApiFp(this.configuration).deletePaidChargeUsingPOST(
      clientId,
      request,
      options
    )(this.axios, this.basePath)
  }

  /**
   * Метод возвращает дополнительную информацию о начислении
   * @summary Запрос детальной информации по начислению
   * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
   * @param {ChargeDetailsRequest} request Тело запроса
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChargeApi
   */
  public getChargeDetailsUsingPOST(clientId: string, request: ChargeDetailsRequest, options?: any) {
    return ChargeApiFp(this.configuration).getChargeDetailsUsingPOST(
      clientId,
      request,
      options
    )(this.axios, this.basePath)
  }

  /**
   * Метод возвращает начисления по всем подпискам пользователя
   * @summary Получение всех начислений для пользователя
   * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
   * @param {SearchUserChargesRequest} request Тело запроса
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChargeApi
   */
  public getChargesByUserIdUsingPOST(clientId: string, request: SearchUserChargesRequest, options?: any) {
    return ChargeApiFp(this.configuration).getChargesByUserIdUsingPOST(
      clientId,
      request,
      options
    )(this.axios, this.basePath)
  }

  /**
   * Метод возвращает итоги по неоплаченным счетам пользователя в разрезе подписок
   * @summary Итоги по неоплаченным счетам пользователя в разрезе подписок
   * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
   * @param {SearchChargesTotalRequest} request Тело запроса
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChargeApi
   */
  public getChargesTotalsUsingPOST(clientId: string, request: SearchChargesTotalRequest, options?: any) {
    return ChargeApiFp(this.configuration).getChargesTotalsUsingPOST(
      clientId,
      request,
      options
    )(this.axios, this.basePath)
  }

  /**
   * Метод предназначен для передачи сохраненных показаний приборов учета клиента при оплате
   * @summary Технологический запрос для передачи сохраненных показаний приборов учета клиента при оплате
   * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
   * @param {GetMetersValuesByProcessRequest} request Тело запроса
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChargeApi
   */
  public getMetersByProcessIdUsingPOST(clientId: string, request: GetMetersValuesByProcessRequest, options?: any) {
    return ChargeApiFp(this.configuration).getMetersByProcessIdUsingPOST(
      clientId,
      request,
      options
    )(this.axios, this.basePath)
  }

  /**
   * Метод предназначен для получение баланса парковок Москвы.
   * @summary Запрос баланса парковок
   * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
   * @param {GetBalanceRequest} request Тело запроса
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChargeApi
   */
  public getParkingBalanceUsingPOST(clientId: string, request: GetBalanceRequest, options?: any) {
    return ChargeApiFp(this.configuration).getParkingBalanceUsingPOST(
      clientId,
      request,
      options
    )(this.axios, this.basePath)
  }

  /**
   * Метод позволяет узнать статус у начисления конкретного пользователя в сервисе начислений
   * @summary Запрос статуса у начисления оплаченного/неоплаченного
   * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
   * @param {PayStatusRequest} request Тело запроса
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChargeApi
   */
  public getPayStatusUsingPOST(clientId: string, request: PayStatusRequest, options?: any) {
    return ChargeApiFp(this.configuration).getPayStatusUsingPOST(clientId, request, options)(this.axios, this.basePath)
  }

  /**
   * Метод инициирует валидацию подписаннного xml документа. В случае успеха, возвращает массив найденных начислений.
   * @summary Запрос создания начислений из личного кабинета клиента ФНС
   * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
   * @param {SearchFNSInvoicesRequest} request Тело запроса
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChargeApi
   */
  public getPaymentsByFNSInvoicesUsingPOST(clientId: string, request: SearchFNSInvoicesRequest, options?: any) {
    return ChargeApiFp(this.configuration).getPaymentsByFNSInvoicesUsingPOST(
      clientId,
      request,
      options
    )(this.axios, this.basePath)
  }

  /**
   * Метод возвращает текущий баланс ЛС клиента у поставщика услуг, показания последних приборов учета,переданные клиентом поставщику услуг (актуально для ЖКУ)
   * @summary Запрос получения текущего баланса ЛС и показаний приборов учета клиента у поставщика услуг
   * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
   * @param {ProviderBalanceAndMetersRequest} request Тело запроса
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChargeApi
   */
  public getProviderBalanceWithCountersUsingPOST(
    clientId: string,
    request: ProviderBalanceAndMetersRequest,
    options?: any
  ) {
    return ChargeApiFp(this.configuration).getProviderBalanceWithCountersUsingPOST(
      clientId,
      request,
      options
    )(this.axios, this.basePath)
  }

  /**
   * Метод возвращает результат поиска начислений по идентификатору заявки на поиск полученной при успешном вызове searchCharges
   * @summary Запрос результатов поиска начислений
   * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
   * @param {SearchChargesResultRequest} request Тело запроса
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChargeApi
   */
  public getSearchChargesResultUsingPOST(clientId: string, request: SearchChargesResultRequest, options?: any) {
    return ChargeApiFp(this.configuration).getSearchChargesResultUsingPOST(
      clientId,
      request,
      options
    )(this.axios, this.basePath)
  }

  /**
   * Метод возвращает результат выполнения команды: принят запрос (0) или нет
   * @summary Принять уведомление о получении отклика на предложение
   * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
   * @param {NotifyResultRequest} request Тело запроса
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChargeApi
   */
  public notifySMSAocResultUsingPOST(clientId: string, request: NotifyResultRequest, options?: any) {
    return ChargeApiFp(this.configuration).notifySMSAocResultUsingPOST(
      clientId,
      request,
      options
    )(this.axios, this.basePath)
  }

  /**
   * Метод позволяет сохранить в сервисе начисление конкретного пользователя как оплаченное, после этого оно не будет появляться в результатах поиска при выставленном признаке \'Скрывать оплаченные начисления\'
   * @summary Запрос сохранения начисления как оплаченного
   * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
   * @param {SavePaidChargeRequest} request Тело запроса
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChargeApi
   */
  public savePaidChargeUsingPOST(clientId: string, request: SavePaidChargeRequest, options?: any) {
    return ChargeApiFp(this.configuration).savePaidChargeUsingPOST(
      clientId,
      request,
      options
    )(this.axios, this.basePath)
  }

  /**
   * Метод инициирует поиск начислений по сохранённым ранее активным подпискам пользователя, возвращая в ответ, в случае успеха, идентификатор поиска, по которому в последствии можно запросить результаты (см. getSearchChargesResult)
   * @summary Запрос поиска начислений по подпискам
   * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
   * @param {SearchChargesBySubscriptionsRequest} request Тело запроса
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChargeApi
   */
  public searchChargesBySubscriptionsUsingPOST(
    clientId: string,
    request: SearchChargesBySubscriptionsRequest,
    options?: any
  ) {
    return ChargeApiFp(this.configuration).searchChargesBySubscriptionsUsingPOST(
      clientId,
      request,
      options
    )(this.axios, this.basePath)
  }

  /**
   * Метод инициирует поиск начислений, возвращая в ответ, в случае успеха, идентификатор поиска, по которому в последствии можно запросить результаты (см. getSearchChargesResult)
   * @summary Запрос поиска начислений
   * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
   * @param {SearchChargesRequest} request Тело запроса
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChargeApi
   */
  public searchChargesUsingPOST(clientId: string, request: SearchChargesRequest, options?: any) {
    return ChargeApiFp(this.configuration).searchChargesUsingPOST(clientId, request, options)(this.axios, this.basePath)
  }

  /**
   * Метод возвращает начисления независимо от статуса оплаты и актуальности начисления
   * @summary Запрос получения краткой информации о начислении
   * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
   * @param {SearchInvoicesRequest} request Тело запроса
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChargeApi
   */
  public searchInvoicesUsingPOST(clientId: string, request: SearchInvoicesRequest, options?: any) {
    return ChargeApiFp(this.configuration).searchInvoicesUsingPOST(
      clientId,
      request,
      options
    )(this.axios, this.basePath)
  }

  /**
   * Метод предназначен для передачи новых показаний приборов учета поставщику услуг
   * @summary Запрос передачи новых показаний приборов учета поставщику услуг
   * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
   * @param {SendMetersValueRequest} request Тело запроса
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChargeApi
   */
  public sendMetersValueUsingPOST(clientId: string, request: SendMetersValueRequest, options?: any) {
    return ChargeApiFp(this.configuration).sendMetersValueUsingPOST(
      clientId,
      request,
      options
    )(this.axios, this.basePath)
  }
}

/**
 * DebugApi - axios parameter creator
 * @export
 */
export const DebugApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Метод предназначен для ручного запуска регламентов
     * @summary Служебный метод запуска регламентов
     * @param {string} schedulerId Имя шедулера (daily, night, fast_invoice, gis_gkh, parking_balance)
     * @param {Array<string>} [providers] Набор providers (MTS,MGTS, ...)
     * @param {number} [repeat] Количество повтороений
     * @param {number} [sleep] Ожидание мс
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runSchedulingUsingGET(
      schedulerId: string,
      providers?: Array<string>,
      repeat?: number,
      sleep?: number,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'schedulerId' is not null or undefined
      if (schedulerId === null || schedulerId === undefined) {
        throw new RequiredError(
          'schedulerId',
          'Required parameter schedulerId was null or undefined when calling runSchedulingUsingGET.'
        )
      }
      const localVarPath = `/b/ftb-charge-invoice/debug/runScheduling/{schedulerId}`.replace(
        `{${'schedulerId'}}`,
        encodeURIComponent(String(schedulerId))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (providers) {
        localVarQueryParameter['providers'] = providers
      }

      if (repeat !== undefined) {
        localVarQueryParameter['repeat'] = repeat
      }

      if (sleep !== undefined) {
        localVarQueryParameter['sleep'] = sleep
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * DebugApi - functional programming interface
 * @export
 */
export const DebugApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Метод предназначен для ручного запуска регламентов
     * @summary Служебный метод запуска регламентов
     * @param {string} schedulerId Имя шедулера (daily, night, fast_invoice, gis_gkh, parking_balance)
     * @param {Array<string>} [providers] Набор providers (MTS,MGTS, ...)
     * @param {number} [repeat] Количество повтороений
     * @param {number} [sleep] Ожидание мс
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runSchedulingUsingGET(
      schedulerId: string,
      providers?: Array<string>,
      repeat?: number,
      sleep?: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbstractResponse> {
      const localVarAxiosArgs = DebugApiAxiosParamCreator(configuration).runSchedulingUsingGET(
        schedulerId,
        providers,
        repeat,
        sleep,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * DebugApi - factory interface
 * @export
 */
export const DebugApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     * Метод предназначен для ручного запуска регламентов
     * @summary Служебный метод запуска регламентов
     * @param {string} schedulerId Имя шедулера (daily, night, fast_invoice, gis_gkh, parking_balance)
     * @param {Array<string>} [providers] Набор providers (MTS,MGTS, ...)
     * @param {number} [repeat] Количество повтороений
     * @param {number} [sleep] Ожидание мс
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    runSchedulingUsingGET(
      schedulerId: string,
      providers?: Array<string>,
      repeat?: number,
      sleep?: number,
      options?: any
    ): AxiosPromise<AbstractResponse> {
      return DebugApiFp(configuration).runSchedulingUsingGET(
        schedulerId,
        providers,
        repeat,
        sleep,
        options
      )(axios, basePath)
    },
  }
}

/**
 * DebugApi - object-oriented interface
 * @export
 * @class DebugApi
 * @extends {BaseAPI}
 */
export class DebugApi extends BaseAPI {
  /**
   * Метод предназначен для ручного запуска регламентов
   * @summary Служебный метод запуска регламентов
   * @param {string} schedulerId Имя шедулера (daily, night, fast_invoice, gis_gkh, parking_balance)
   * @param {Array<string>} [providers] Набор providers (MTS,MGTS, ...)
   * @param {number} [repeat] Количество повтороений
   * @param {number} [sleep] Ожидание мс
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DebugApi
   */
  public runSchedulingUsingGET(
    schedulerId: string,
    providers?: Array<string>,
    repeat?: number,
    sleep?: number,
    options?: any
  ) {
    return DebugApiFp(this.configuration).runSchedulingUsingGET(
      schedulerId,
      providers,
      repeat,
      sleep,
      options
    )(this.axios, this.basePath)
  }
}

/**
 * FileManagerApi - axios parameter creator
 * @export
 */
export const FileManagerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Возвращает список фотографий по пачислениям
     * @summary Запрос фотографий по начислениям
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {FileManagerRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFinesPhotoUsingPOST(clientId: string, request: FileManagerRequest, options: any = {}): RequestArgs {
      // verify required parameter 'clientId' is not null or undefined
      if (clientId === null || clientId === undefined) {
        throw new RequiredError(
          'clientId',
          'Required parameter clientId was null or undefined when calling getFinesPhotoUsingPOST.'
        )
      }
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          'request',
          'Required parameter request was null or undefined when calling getFinesPhotoUsingPOST.'
        )
      }
      const localVarPath = `/b/ftb-charge-invoice/file-manager/1.0/{clientId}/getFinesPhotos`.replace(
        `{${'clientId'}}`,
        encodeURIComponent(String(clientId))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof request !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(request !== undefined ? request : {})
        : request || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Возвращает список фотографий по УИН
     * @summary Запрос фотографий по УИН
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {FileManagerUinRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFinesPhotosByUINUsingPOST(clientId: string, request: FileManagerUinRequest, options: any = {}): RequestArgs {
      // verify required parameter 'clientId' is not null or undefined
      if (clientId === null || clientId === undefined) {
        throw new RequiredError(
          'clientId',
          'Required parameter clientId was null or undefined when calling getFinesPhotosByUINUsingPOST.'
        )
      }
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          'request',
          'Required parameter request was null or undefined when calling getFinesPhotosByUINUsingPOST.'
        )
      }
      const localVarPath = `/b/ftb-charge-invoice/file-manager/1.0/{clientId}/getFinesPhotosByUIN`.replace(
        `{${'clientId'}}`,
        encodeURIComponent(String(clientId))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof request !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(request !== undefined ? request : {})
        : request || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * FileManagerApi - functional programming interface
 * @export
 */
export const FileManagerApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Возвращает список фотографий по пачислениям
     * @summary Запрос фотографий по начислениям
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {FileManagerRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFinesPhotoUsingPOST(
      clientId: string,
      request: FileManagerRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileManagerResponse> {
      const localVarAxiosArgs = FileManagerApiAxiosParamCreator(configuration).getFinesPhotoUsingPOST(
        clientId,
        request,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Возвращает список фотографий по УИН
     * @summary Запрос фотографий по УИН
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {FileManagerUinRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFinesPhotosByUINUsingPOST(
      clientId: string,
      request: FileManagerUinRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileManagerUinResponse> {
      const localVarAxiosArgs = FileManagerApiAxiosParamCreator(configuration).getFinesPhotosByUINUsingPOST(
        clientId,
        request,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * FileManagerApi - factory interface
 * @export
 */
export const FileManagerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     * Возвращает список фотографий по пачислениям
     * @summary Запрос фотографий по начислениям
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {FileManagerRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFinesPhotoUsingPOST(
      clientId: string,
      request: FileManagerRequest,
      options?: any
    ): AxiosPromise<FileManagerResponse> {
      return FileManagerApiFp(configuration).getFinesPhotoUsingPOST(clientId, request, options)(axios, basePath)
    },
    /**
     * Возвращает список фотографий по УИН
     * @summary Запрос фотографий по УИН
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {FileManagerUinRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFinesPhotosByUINUsingPOST(
      clientId: string,
      request: FileManagerUinRequest,
      options?: any
    ): AxiosPromise<FileManagerUinResponse> {
      return FileManagerApiFp(configuration).getFinesPhotosByUINUsingPOST(clientId, request, options)(axios, basePath)
    },
  }
}

/**
 * FileManagerApi - object-oriented interface
 * @export
 * @class FileManagerApi
 * @extends {BaseAPI}
 */
export class FileManagerApi extends BaseAPI {
  /**
   * Возвращает список фотографий по пачислениям
   * @summary Запрос фотографий по начислениям
   * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
   * @param {FileManagerRequest} request Тело запроса
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FileManagerApi
   */
  public getFinesPhotoUsingPOST(clientId: string, request: FileManagerRequest, options?: any) {
    return FileManagerApiFp(this.configuration).getFinesPhotoUsingPOST(
      clientId,
      request,
      options
    )(this.axios, this.basePath)
  }

  /**
   * Возвращает список фотографий по УИН
   * @summary Запрос фотографий по УИН
   * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
   * @param {FileManagerUinRequest} request Тело запроса
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FileManagerApi
   */
  public getFinesPhotosByUINUsingPOST(clientId: string, request: FileManagerUinRequest, options?: any) {
    return FileManagerApiFp(this.configuration).getFinesPhotosByUINUsingPOST(
      clientId,
      request,
      options
    )(this.axios, this.basePath)
  }
}

/**
 * InfogateApi - axios parameter creator
 * @export
 */
export const InfogateApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Клиент получает ваучер PS Store
     * @summary Получение кода PS Store
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {PSStoreCodeReceivingRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    psStoreCodeReceivingUsingPOST(
      clientId: string,
      request: PSStoreCodeReceivingRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'clientId' is not null or undefined
      if (clientId === null || clientId === undefined) {
        throw new RequiredError(
          'clientId',
          'Required parameter clientId was null or undefined when calling psStoreCodeReceivingUsingPOST.'
        )
      }
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          'request',
          'Required parameter request was null or undefined when calling psStoreCodeReceivingUsingPOST.'
        )
      }
      const localVarPath = `/b/ftb-charge-invoice/infogate/1.0/{clientId}/psStoreCodeReceiving`.replace(
        `{${'clientId'}}`,
        encodeURIComponent(String(clientId))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof request !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(request !== undefined ? request : {})
        : request || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * InfogateApi - functional programming interface
 * @export
 */
export const InfogateApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Клиент получает ваучер PS Store
     * @summary Получение кода PS Store
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {PSStoreCodeReceivingRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    psStoreCodeReceivingUsingPOST(
      clientId: string,
      request: PSStoreCodeReceivingRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbstractResponse> {
      const localVarAxiosArgs = InfogateApiAxiosParamCreator(configuration).psStoreCodeReceivingUsingPOST(
        clientId,
        request,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * InfogateApi - factory interface
 * @export
 */
export const InfogateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     * Клиент получает ваучер PS Store
     * @summary Получение кода PS Store
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {PSStoreCodeReceivingRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    psStoreCodeReceivingUsingPOST(
      clientId: string,
      request: PSStoreCodeReceivingRequest,
      options?: any
    ): AxiosPromise<AbstractResponse> {
      return InfogateApiFp(configuration).psStoreCodeReceivingUsingPOST(clientId, request, options)(axios, basePath)
    },
  }
}

/**
 * InfogateApi - object-oriented interface
 * @export
 * @class InfogateApi
 * @extends {BaseAPI}
 */
export class InfogateApi extends BaseAPI {
  /**
   * Клиент получает ваучер PS Store
   * @summary Получение кода PS Store
   * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
   * @param {PSStoreCodeReceivingRequest} request Тело запроса
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InfogateApi
   */
  public psStoreCodeReceivingUsingPOST(clientId: string, request: PSStoreCodeReceivingRequest, options?: any) {
    return InfogateApiFp(this.configuration).psStoreCodeReceivingUsingPOST(
      clientId,
      request,
      options
    )(this.axios, this.basePath)
  }
}

/**
 * SubscriptionApi - axios parameter creator
 * @export
 */
export const SubscriptionApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Возвращает идентификатор созданной подписки в случае успеха
     * @summary Запрос на создание подписки
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {CreateSubscriptionRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSubscriptionUsingPOST(clientId: string, request: CreateSubscriptionRequest, options: any = {}): RequestArgs {
      // verify required parameter 'clientId' is not null or undefined
      if (clientId === null || clientId === undefined) {
        throw new RequiredError(
          'clientId',
          'Required parameter clientId was null or undefined when calling createSubscriptionUsingPOST.'
        )
      }
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          'request',
          'Required parameter request was null or undefined when calling createSubscriptionUsingPOST.'
        )
      }
      const localVarPath = `/b/ftb-charge-invoice/subscriptions/1.0/{clientId}/createSubscription`.replace(
        `{${'clientId'}}`,
        encodeURIComponent(String(clientId))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof request !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(request !== undefined ? request : {})
        : request || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Запрос на удаление подписки
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {DeleteSubscriptionRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSubscriptionsUsingPOST(clientId: string, request: DeleteSubscriptionRequest, options: any = {}): RequestArgs {
      // verify required parameter 'clientId' is not null or undefined
      if (clientId === null || clientId === undefined) {
        throw new RequiredError(
          'clientId',
          'Required parameter clientId was null or undefined when calling deleteSubscriptionsUsingPOST.'
        )
      }
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          'request',
          'Required parameter request was null or undefined when calling deleteSubscriptionsUsingPOST.'
        )
      }
      const localVarPath = `/b/ftb-charge-invoice/subscriptions/1.0/{clientId}/deleteSubscription`.replace(
        `{${'clientId'}}`,
        encodeURIComponent(String(clientId))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof request !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(request !== undefined ? request : {})
        : request || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Запрос на поиск подписки по параметрам
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {GetSubscriptionByFilterRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscriptionsByFilterUsingPOST(
      clientId: string,
      request: GetSubscriptionByFilterRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'clientId' is not null or undefined
      if (clientId === null || clientId === undefined) {
        throw new RequiredError(
          'clientId',
          'Required parameter clientId was null or undefined when calling getSubscriptionsByFilterUsingPOST.'
        )
      }
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          'request',
          'Required parameter request was null or undefined when calling getSubscriptionsByFilterUsingPOST.'
        )
      }
      const localVarPath = `/b/ftb-charge-invoice/subscriptions/1.0/{clientId}/findSubscriptions`.replace(
        `{${'clientId'}}`,
        encodeURIComponent(String(clientId))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof request !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(request !== undefined ? request : {})
        : request || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Возвращает отфильтрованный список подписок на поиск пачислений
     * @summary Запрос списка подписок
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {SubscriptionListRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscriptionsUsingPOST(clientId: string, request: SubscriptionListRequest, options: any = {}): RequestArgs {
      // verify required parameter 'clientId' is not null or undefined
      if (clientId === null || clientId === undefined) {
        throw new RequiredError(
          'clientId',
          'Required parameter clientId was null or undefined when calling getSubscriptionsUsingPOST.'
        )
      }
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          'request',
          'Required parameter request was null or undefined when calling getSubscriptionsUsingPOST.'
        )
      }
      const localVarPath = `/b/ftb-charge-invoice/subscriptions/1.0/{clientId}/getSubscriptions`.replace(
        `{${'clientId'}}`,
        encodeURIComponent(String(clientId))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof request !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(request !== undefined ? request : {})
        : request || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Запрос на изменение подписки
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {UpdateSubscriptionRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSubscriptionsUsingPOST(clientId: string, request: UpdateSubscriptionRequest, options: any = {}): RequestArgs {
      // verify required parameter 'clientId' is not null or undefined
      if (clientId === null || clientId === undefined) {
        throw new RequiredError(
          'clientId',
          'Required parameter clientId was null or undefined when calling updateSubscriptionsUsingPOST.'
        )
      }
      // verify required parameter 'request' is not null or undefined
      if (request === null || request === undefined) {
        throw new RequiredError(
          'request',
          'Required parameter request was null or undefined when calling updateSubscriptionsUsingPOST.'
        )
      }
      const localVarPath = `/b/ftb-charge-invoice/subscriptions/1.0/{clientId}/updateSubscription`.replace(
        `{${'clientId'}}`,
        encodeURIComponent(String(clientId))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof request !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(request !== undefined ? request : {})
        : request || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * SubscriptionApi - functional programming interface
 * @export
 */
export const SubscriptionApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Возвращает идентификатор созданной подписки в случае успеха
     * @summary Запрос на создание подписки
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {CreateSubscriptionRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSubscriptionUsingPOST(
      clientId: string,
      request: CreateSubscriptionRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateSubscriptionResponse> {
      const localVarAxiosArgs = SubscriptionApiAxiosParamCreator(configuration).createSubscriptionUsingPOST(
        clientId,
        request,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Запрос на удаление подписки
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {DeleteSubscriptionRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSubscriptionsUsingPOST(
      clientId: string,
      request: DeleteSubscriptionRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteSubscriptionResponse> {
      const localVarAxiosArgs = SubscriptionApiAxiosParamCreator(configuration).deleteSubscriptionsUsingPOST(
        clientId,
        request,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Запрос на поиск подписки по параметрам
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {GetSubscriptionByFilterRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscriptionsByFilterUsingPOST(
      clientId: string,
      request: GetSubscriptionByFilterRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSubscriptionByFilterResponse> {
      const localVarAxiosArgs = SubscriptionApiAxiosParamCreator(configuration).getSubscriptionsByFilterUsingPOST(
        clientId,
        request,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Возвращает отфильтрованный список подписок на поиск пачислений
     * @summary Запрос списка подписок
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {SubscriptionListRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscriptionsUsingPOST(
      clientId: string,
      request: SubscriptionListRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionListResponse> {
      const localVarAxiosArgs = SubscriptionApiAxiosParamCreator(configuration).getSubscriptionsUsingPOST(
        clientId,
        request,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Запрос на изменение подписки
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {UpdateSubscriptionRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSubscriptionsUsingPOST(
      clientId: string,
      request: UpdateSubscriptionRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSubscriptionResponse> {
      const localVarAxiosArgs = SubscriptionApiAxiosParamCreator(configuration).updateSubscriptionsUsingPOST(
        clientId,
        request,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * SubscriptionApi - factory interface
 * @export
 */
export const SubscriptionApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     * Возвращает идентификатор созданной подписки в случае успеха
     * @summary Запрос на создание подписки
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {CreateSubscriptionRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSubscriptionUsingPOST(
      clientId: string,
      request: CreateSubscriptionRequest,
      options?: any
    ): AxiosPromise<CreateSubscriptionResponse> {
      return SubscriptionApiFp(configuration).createSubscriptionUsingPOST(clientId, request, options)(axios, basePath)
    },
    /**
     *
     * @summary Запрос на удаление подписки
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {DeleteSubscriptionRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSubscriptionsUsingPOST(
      clientId: string,
      request: DeleteSubscriptionRequest,
      options?: any
    ): AxiosPromise<DeleteSubscriptionResponse> {
      return SubscriptionApiFp(configuration).deleteSubscriptionsUsingPOST(clientId, request, options)(axios, basePath)
    },
    /**
     *
     * @summary Запрос на поиск подписки по параметрам
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {GetSubscriptionByFilterRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscriptionsByFilterUsingPOST(
      clientId: string,
      request: GetSubscriptionByFilterRequest,
      options?: any
    ): AxiosPromise<GetSubscriptionByFilterResponse> {
      return SubscriptionApiFp(configuration).getSubscriptionsByFilterUsingPOST(
        clientId,
        request,
        options
      )(axios, basePath)
    },
    /**
     * Возвращает отфильтрованный список подписок на поиск пачислений
     * @summary Запрос списка подписок
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {SubscriptionListRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubscriptionsUsingPOST(
      clientId: string,
      request: SubscriptionListRequest,
      options?: any
    ): AxiosPromise<SubscriptionListResponse> {
      return SubscriptionApiFp(configuration).getSubscriptionsUsingPOST(clientId, request, options)(axios, basePath)
    },
    /**
     *
     * @summary Запрос на изменение подписки
     * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
     * @param {UpdateSubscriptionRequest} request Тело запроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSubscriptionsUsingPOST(
      clientId: string,
      request: UpdateSubscriptionRequest,
      options?: any
    ): AxiosPromise<UpdateSubscriptionResponse> {
      return SubscriptionApiFp(configuration).updateSubscriptionsUsingPOST(clientId, request, options)(axios, basePath)
    },
  }
}

/**
 * SubscriptionApi - object-oriented interface
 * @export
 * @class SubscriptionApi
 * @extends {BaseAPI}
 */
export class SubscriptionApi extends BaseAPI {
  /**
   * Возвращает идентификатор созданной подписки в случае успеха
   * @summary Запрос на создание подписки
   * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
   * @param {CreateSubscriptionRequest} request Тело запроса
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscriptionApi
   */
  public createSubscriptionUsingPOST(clientId: string, request: CreateSubscriptionRequest, options?: any) {
    return SubscriptionApiFp(this.configuration).createSubscriptionUsingPOST(
      clientId,
      request,
      options
    )(this.axios, this.basePath)
  }

  /**
   *
   * @summary Запрос на удаление подписки
   * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
   * @param {DeleteSubscriptionRequest} request Тело запроса
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscriptionApi
   */
  public deleteSubscriptionsUsingPOST(clientId: string, request: DeleteSubscriptionRequest, options?: any) {
    return SubscriptionApiFp(this.configuration).deleteSubscriptionsUsingPOST(
      clientId,
      request,
      options
    )(this.axios, this.basePath)
  }

  /**
   *
   * @summary Запрос на поиск подписки по параметрам
   * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
   * @param {GetSubscriptionByFilterRequest} request Тело запроса
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscriptionApi
   */
  public getSubscriptionsByFilterUsingPOST(clientId: string, request: GetSubscriptionByFilterRequest, options?: any) {
    return SubscriptionApiFp(this.configuration).getSubscriptionsByFilterUsingPOST(
      clientId,
      request,
      options
    )(this.axios, this.basePath)
  }

  /**
   * Возвращает отфильтрованный список подписок на поиск пачислений
   * @summary Запрос списка подписок
   * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
   * @param {SubscriptionListRequest} request Тело запроса
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscriptionApi
   */
  public getSubscriptionsUsingPOST(clientId: string, request: SubscriptionListRequest, options?: any) {
    return SubscriptionApiFp(this.configuration).getSubscriptionsUsingPOST(
      clientId,
      request,
      options
    )(this.axios, this.basePath)
  }

  /**
   *
   * @summary Запрос на изменение подписки
   * @param {string} clientId Идентификатор клиента сервиса (системы, приславшей запрос, например, mtsmon_site)
   * @param {UpdateSubscriptionRequest} request Тело запроса
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscriptionApi
   */
  public updateSubscriptionsUsingPOST(clientId: string, request: UpdateSubscriptionRequest, options?: any) {
    return SubscriptionApiFp(this.configuration).updateSubscriptionsUsingPOST(
      clientId,
      request,
      options
    )(this.axios, this.basePath)
  }
}
